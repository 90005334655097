import React, { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Chip from '@mui/material/Chip';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import CircularProgress from '@mui/material/CircularProgress';
import {executeAutosellTrades} from '../contents/utils'
import Button from '@mui/material/Button';
import TransactionInfoModal from './TransactionInfoModal'
import LoadingButton from '@mui/lab/LoadingButton';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
    ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
    ) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
interface HeadCell {
    disablePadding: boolean;
    id: string;
    label: string;
    numeric: boolean;
  }
const headCells: readonly HeadCell[] = [
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Name',
    },
    {
        id: 'adderss',
        numeric: false,
        disablePadding: false,
        label: 'Address',
    },
    {
        id: 'asset',
        numeric: false,
        disablePadding: false,
        label: 'Asset',
    },
    {
        id: 'amount',
        numeric: false,
        disablePadding: false,
        label: 'Amount',
    },
    {
        id: 'assetpair',
        numeric: false,
        disablePadding: false,
        label: 'Asset Pair',
    },
    {
        id: 'txid',
        numeric: false,
        disablePadding: false,
        label: 'Transaction Id',
    },
    {
        id: 'executed',
        numeric: false,
        disablePadding: false,
        label: 'Trade executed',
    },
];
function EnhancedTableHead({onSelectAllClick, numSelected, rowCount, txType } : any) {
    return (
        <TableHead>
            <TableRow>
            {txType === 'autosell' ?
                <TableCell padding="checkbox">
                    {/* <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    /> */}
                </TableCell> 
            : null}
            {headCells.map((headCell) => txType === 'monitor' && headCell.id === 'executed' ? null : (
                <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? 'right' : 'left'}
                    padding={headCell.disablePadding ? 'none' : 'normal'}
                >
                    {headCell.label}
                </TableCell>
            ))}
            </TableRow>
        </TableHead>
    );
}
const EnhancedTableToolbar = ({ numSelected, tableName, onTradeExecution, loading } : any) => {
    return (
        <Toolbar
            sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            ...(numSelected > 0 && {
                bgcolor: (theme) =>
                alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
            }),
            }}
        >
            {numSelected > 0 ? 
                (<Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
                ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    {tableName}
                </Typography>
                )}
            {numSelected > 0 ? loading? 
            (<LoadingButton loading variant="outlined" >
                Executing
            </LoadingButton>)
            :(<Tooltip title="Execuate trade">
                <Button variant="contained" onClick={onTradeExecution} disabled={loading} endIcon={<CurrencyExchangeIcon />}>
                    Execute Trades
                </Button>
            </Tooltip>
            ) : null}
        </Toolbar>
    );
};
function TransactionTableDialog({data, open, handleClose, tableName, chain, txType, setTradeLoading, tradeLoading, handleTradeUpdate} : any) {
    // console.log(data)
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [selectedTrades, setSelectedTrades] = React.useState<[]>([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [tradeList, setTradeList] = React.useState<[]>([]);
    const [tradeConfirmation, setTradeConfirmation] = React.useState<[]>([]);
    const [openTradeExecutionDetail, setOpenTradeExecutionDetail] = React.useState(false);
    const [selectedTx, setSelectedTx] = React.useState<number>(0);
    const [currentButton, setCurrentButton] = React.useState<string>('all');
    // const [updated, setUpdated] = React.useState(false);
    useEffect(() => {
        setTradeList(data)
        setSelected([])
    },[data])
    const filterTradedTx = () => {
        let tradedTx = data.filter((tx:any) => tx.Recorded === true)
        setTradeList(tradedTx)
        setCurrentButton('traded')
    }
    const filterUntradedTx = () => {
        let tradedTx = data.filter((tx:any) => tx.Recorded === false)
        setTradeList(tradedTx)
        setCurrentButton('untraded')
    }
    const cancelAllfilter = () => {
        setTradeList(data)
        setCurrentButton('all')
    }
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = data.map((n: any) => n.Recorded ? null : n.TxID);
            const allTradableData = data.filter((t: any) => t.Recorded === false)
            console.log(newSelected);
            console.log(allTradableData);
            
            setSelected(newSelected);
            setSelectedTrades(allTradableData)
            return;
        }
        setSelected([]);
        setSelectedTrades([]);
    };
    const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: readonly string[] = [];
    
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const isSelected = (name: string) => {
        console.log(selected.length);
        console.log(name);
        console.log(selected)
        return selected.indexOf(name) !== -1;
    }
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;
    const performTrades = async() => {
        // console.log(selected)
        setTradeLoading(true)
        console.log(selected)
        console.log(data)
        let trades = selected.map((s :any) => data.filter((t:any) => t.TxID === s)[0])
        console.log(trades)
        let res = await executeAutosellTrades(trades)
        console.log(res)
        if(res){
            setTradeConfirmation(res.data.TradeConfirmations)
            setTradeList(res.data.TradeList)
            handleTradeUpdate()
            setSelected([])
        }
        setTradeLoading(false)
    }
    const openTradeConfirmationDetail = (row : number) => {
        setOpenTradeExecutionDetail(true)
        setSelectedTx(row)
    }
    const closeTradeConfirmationDetail = () => {
        setOpenTradeExecutionDetail(false)
        setSelectedTx(0)
    }
    return (
        <Dialog onClose={handleClose} open={open} maxWidth="xl">
            <Box sx={{ width: '100%' }}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <EnhancedTableToolbar numSelected={selected.length} tableName={tableName} onTradeExecution={performTrades} loading={tradeLoading}/>
                    <div style={{margin: 10}}>
                        <Chip label="Show All" sx={{marginRight: 2}} color={currentButton == "all" ? "primary" : "default"} variant="outlined" onClick={() => cancelAllfilter()} />
                        <Chip label="Show Traded" sx={{marginRight: 2}} color={currentButton == "traded" ? "primary" : "default"} variant="outlined" onClick={() => filterTradedTx()} />
                        <Chip label="Show Not Executed" color={currentButton == "untraded" ? "primary" : "default"} variant="outlined" onClick={() => filterUntradedTx()} />
                    </div>
                    <TableContainer>
                    <Table
                        sx={{ minWidth: 1000 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            onSelectAllClick={handleSelectAllClick}
                            rowCount={tradeList.length}
                            txType={txType}
                        />
                        <TableBody>
                        {tradeList.length > 0 && tradeList
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row: any, index: any) => {
                                console.log(row)
                            const isItemSelected = isSelected(row.TxID);
                            console.log(isItemSelected);
                            
                            const labelId = `enhanced-table-checkbox-${index}`;
                            if(txType === 'monitor') {
                                return (
                                    <TableRow 
                                        key={row.TxID} 
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="normal"
                                        >
                                        {row.Name}
                                        </TableCell>
                                        <TableCell align="left">{row.Address}</TableCell>
                                        <TableCell align="left">{row.Asset}</TableCell>
                                        <TableCell align="left">{row.Amount}</TableCell>
                                        <TableCell align="left">{row.AssetPair}</TableCell>
                                        <Tooltip title={row.TxID} placement="top"><TableCell align='right'>{row.TxID.substr(0, 10) + '...' + row.TxID.substr(row.TxID.length-10, row.TxID.length)}</TableCell></Tooltip>
                                    </TableRow>
                                )
                            }
                            else return (
                                <TableRow
                                    hover
                                    onClick={row.Recorded ? undefined : (event) => handleClick(event, row.TxID)}
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.TxID}
                                    selected={isItemSelected && !row.Recorded}
                                >
                                    {row.TxID ?
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            color="primary"
                                            checked={isItemSelected && !row.Recorded}
                                            disabled={row.Recorded}
                                            inputProps={{
                                                'aria-labelledby': labelId,
                                            }}
                                        />
                                    </TableCell>: null }
                                    <TableCell
                                        component="th"
                                        id={labelId}
                                        scope="row"
                                        padding="none"
                                    >
                                        {row.Name}
                                    </TableCell>
                                    <Tooltip title={row.Address} placement="top"><TableCell align='right'>{row.Address.substr(0, 7) + '...' + row.Address.substr(row.Address.length-7, row.Address.length)}</TableCell></Tooltip>
                                    {/* <TableCell align="right">{row.Address}</TableCell> */}
                                    <TableCell align="right">{row.Asset}</TableCell>
                                    <TableCell align="right">{row.Amount}</TableCell>
                                    <TableCell align="left">{row.AssetPair}</TableCell>
                                    <Tooltip title={row.TxID} placement="top"><TableCell align='right'>{row.TxID.substr(0, 10) + '...' + row.TxID.substr(row.TxID.length-10, row.TxID.length)}</TableCell></Tooltip>
                                    <TableCell align="left">{row.Recorded ? <Chip label="Traded" color="success" variant="outlined" /> : <Chip label="Not executed" variant="outlined" />}</TableCell>
                                    {/* <TableCell align="right">{chain === 'BTC' ? row.TxID : chain === 'ETH' ? row.HashID : row.TxID}</TableCell> */}
                                </TableRow>
                            );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                style={{
                                    height: (dense ? 33 : 53) * emptyRows,
                                }}
                                >
                                <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={tradeList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Box>
            <TransactionInfoModal 
                openDialog={openTradeExecutionDetail} 
                closeDialog={closeTradeConfirmationDetail}
                data={tradeConfirmation[selectedTx]}
            />
        </Dialog>
    );
}

export default TransactionTableDialog;