import React, {useState, useEffect} from 'react';
import {NavLink} from 'react-router-dom'
import './Layout.css'
import Button from '@mui/material/Button';
import { Typography } from 'antd';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useMode } from 'src/hooks/ModeContext';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import moment from 'moment';
import Switch, { SwitchProps } from '@mui/material/Switch';
import serverApi from '../../api/server';
import generateSignature from '../../api/apiAuth'
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth0 } from "@auth0/auth0-react";
import {useCtx} from '../../App'
import Logo from '../../assets/sdm-logo.svg'
const { Title } = Typography;


const AxeStatusSwitch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        backgroundColor: 'red',
        borderRadius: 22 / 2,
            '&:before, &:after': {
                content: '""',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                width: 16,
                height: 16,
            },
            '&:before': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.error.main),
                )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
                left: 12,
            },
            '&:after': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.success.main),
                )}" d="M19,13H5V11H19V13Z" /></svg>')`,
                right: 12,
            },
        },
        '& .MuiSwitch-thumb': {
            boxShadow: 'none',
            width: 16,
            height: 16,
            margin: 2,
        },
}));

function Layout({children} : any) {
    const { logout } = useAuth0();
    const [state, action] = useMode()
    const [currentAxeStatus, setCurrentAxeStatus] = useState(true)
    const getCurrentAxeStatus = async () => {

        const timeStamp = moment().seconds(0).milliseconds(0).toISOString().slice(0, -1) + "000Z"
        const path = '/api/getAxeMode'

        const signature = generateSignature("GET", timeStamp, path, null, null) 
        console.log({signature})

        let status = await serverApi.get('/getAxeMode', {headers: {Authorization: signature}})
        console.log(status)
        if(status.data === 'Online'){
            setCurrentAxeStatus(true)
        }else {
            setCurrentAxeStatus(false)
        }
        
    }
    useEffect(() => {
        getCurrentAxeStatus()
    },[])
    const handleSiteSwitch = () => {
        if(state.mode === 'sdm') {
            action.changeMode('gl')
        }else {
            action.changeMode('sdm')
        }
    }
    const handleStatusChange = async(event: React.ChangeEvent<HTMLInputElement>) => {
        let statusReq;
        console.log(event.target.checked)
        let mode
        if(event.target.checked){
            mode = 'Online'
        }
        else {
            mode = 'Offline'
        }

        const timeStamp = moment().seconds(0).milliseconds(0).toISOString().slice(0, -1) + "000Z"
        const path = '/api/changeAxeMode' 

        const data = {
            axeMode: mode
        }

        const signature = generateSignature("POST", timeStamp, path, null, data) 

        statusReq = await serverApi.post('/changeAxeMode', data, {headers: {Authorization: signature}})

        console.log(statusReq)
        if(statusReq.status){
            console.log(event.target.checked)
            setCurrentAxeStatus(!event.target.checked);
        }
    }
    const logoutAuth0 = () => {
        console.log("log out")
        logout({ returnTo: window.location.origin })
    }
    console.log(currentAxeStatus)

    const axeEnv = useCtx()
    const isAxmed = axeEnv === 'Sandbox'

    return (
        <>
            <div className="header">
                {state.mode === 'sdm' ? 
                    isAxmed? 
                        <Title style={{marginLeft:'10x', color: '#ffff'}}>AXMED</Title>
                        :<img className='headerImage' src={Logo} alt="SDM" height={40} width='auto'/>
                    :<img className='headerImage' src={require("../../assets/Greenline-Logo.png")} alt="GL" height={40} width='auto'/>}
                {/* <Title level={4} className='headerMode'>{state.mode === 'sdm' ? 
                'SDM'
                : 'GREEN-LINE'}</Title> */}
                <NavLink 
                    className="headerItem" to='/' 
                    style={({ isActive }) => {
                        return {
                            color: isActive ? "#ffef96" : "",
                        };
                    }}
                >
                    Overview
                </NavLink>
                <NavLink 
                    className="headerItem" to="/trades"
                    style={({ isActive }) => {
                        return {
                            color: isActive ? "#ffef96" : "",
                        };
                    }}
                >
                    Trades
                </NavLink>
                <NavLink 
                    className="headerItem" to="/blockchain"
                    style={({ isActive }) => {
                        return {
                            color: isActive ? "#ffef96" : "",
                        };
                    }}
                >
                    Blockchain
                </NavLink>
                 {/* {!isAxmed && (
                    <NavLink 
                        className="headerItem" to="/audit"
                        style={({ isActive }) => {
                            return {
                                color: isActive ? "#ffef96" : "",
                            };
                        }}
                    >
                        Audit
                    </NavLink>   
                )} */}
                    <NavLink 
                    className="headerItem" to="/sendernames"
                    style={({ isActive }) => {
                        return {
                            color: isActive ? "#ffef96" : "",
                        };
                    }}
                >
                    Sender Names
                </NavLink>
                <FormControlLabel
                    style={isAxmed? {margin: 'auto', marginRight: '5%'} :{margin: 'auto', marginRight: 0}}
                    control={<AxeStatusSwitch checked={currentAxeStatus} color="success" onChange={handleStatusChange}/>}
                    label={<span style={{ color: 'white'}}>{currentAxeStatus ? 'Online' : 'Offline'}</span>}
                />
                {/* {currentAxeStatus ? 
                    <div><span className="colored-circle" style={{backgroundColor: 'green'}} /></div>
                    : <span className="colored-circle" style={{backgroundColor: 'red'}} />  } */}

                {isAxmed? null : 
                <Button variant="outlined" startIcon={<AutorenewIcon />} onClick={() => handleSiteSwitch()} 
                    sx={{marginLeft: 5,marginRight: 5, height: '80%', justifySelf: 'center', alignSelf: 'center', border: '3px solid white', color: 'white' }}>
                    Switch To {state.mode === 'sdm' ? 'Greenline' : 'SDM'}
                </Button>}
                <Button variant="text" startIcon={<LogoutIcon />} onClick={() => logoutAuth0()} 
                    sx={{marginLeft: 5,marginRight: 5, height: '80%', justifySelf: 'center', alignSelf: 'center', color: 'white' }}>
                    Sign Out
                </Button>
            </div>
            <div className='container'>
                {children}
            </div>
        </>
        
    );
}

export default Layout;