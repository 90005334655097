import instance from '../../../api/server'

export type ChainType = "btcMode" | "ethMode" | "fireblocksMode"
export type ChainMode = "Online" | "Offline" | "Checking"
export type blockCheckingMethod = 'number' | 'range' | 'date'
export type transactionResult = {
    autoSell: Array<any>;
    monitorOnly: Array<any>;
} | false; 
export const getCurrentChainStatus = async(chain: ChainType) => {
    try{
        console.log(chain)
        let result = await instance.get('/getServiceMode?servicetype=' + chain)
        console.log(result)
        return result.data
    }catch(err){
        console.log(err)
        return false
    }
}
export const changeCurrentChainStatus = async(chain: ChainType, mode: ChainMode) => {
    try{
        let result = await instance.post("/changeServiceMode", {newmode: mode, servicetype: chain})
        console.log(result)
        if(result.data == "Mode successfully changed"){
            return true
        }
        else {
            return false
        }
    }
    catch (err){
        console.log(err)
        return false
    }
}
export const getCurrentBTCBlock = async() => {
    try{
        let result = await instance.get("/getBTCBlocks")
        console.log(result)
        let formatted = JSON.parse(result.data)
        console.log(formatted)
        return formatted
    }
    catch(err){
        console.log(err)
        return false
    }
}

export const checkBTCTrades = async(checkMethod: blockCheckingMethod, blocklist: string[], blockrange: string[], daterange: string[]) => {
    try{
        let query;
        if(checkMethod === 'number'){
            let blockQuery = ''
            blocklist.forEach(n => blockQuery += n + ',')
            console.log(blockQuery)
            query = 'blocklist=' + blockQuery.slice(0, -1)
        }
        else if (checkMethod === 'range'){
            console.log(blockrange)
            query = 'startblock=' + blockrange[0] + '&endblock=' + blockrange[1]
        }
        else {
            query = 'startdate=' + daterange[0] + '&enddate=' + daterange[1]
        }
        let result = await instance.get("/checkBTCBlockTrans?" + query)
        console.log(result)
        if(result.status === 200) {
            let autosellTransactions = result.data[0]
            let monitorOnlyTransactions = result.data[1]
            return {autoSell: autosellTransactions, monitorOnly: monitorOnlyTransactions}
        }
        else {
            return false
        }
    }
    catch (err) {
        console.log(err)
        return false
    }
}

export const getCurrentETHBlock = async() => {
    try{
        let result = await instance.get("/getETHBlocks")
        console.log(result)
        let formatted = JSON.parse(result.data)
        console.log(formatted)
        return formatted
    }
    catch(err){
        console.log(err)
        return false
    }
}
export const checkETHTrades = async(checkMethod: blockCheckingMethod, blocklist: string[], blockrange: string[], daterange: string[]) => {
    try{
        let query;
        if(checkMethod === 'number'){
            let blockQuery = ''
            blocklist.forEach(n => blockQuery += n + ',')
            console.log(blockQuery)
            query = 'blocklist=' + blockQuery.slice(0, -1)
        }
        else if (checkMethod === 'range'){
            console.log(blockrange)
            query = 'startblock=' + blockrange[0] + '&endblock=' + blockrange[1]
        }
        else {
            query = 'startdate=' + daterange[0] + '&enddate=' + daterange[1]
        }
        console.log(query)
        let result = await instance.get("/checkETHBlockTrans?" + query)
        if(result.status === 200) {
            let autosellTransactions = result.data[0]
            let monitorOnlyTransactions = result.data[1]
            return {autoSell: autosellTransactions, monitorOnly: monitorOnlyTransactions}
        }
        else {
            return false
        }
    }
    catch(err){
        console.log(err)
        return false
    }
}
export const checkFBTrades = async(chainType: string, checkMethod: blockCheckingMethod, blocklist: string[], blockrange: string[], daterange: string[]) => {
    try{
        let query = 'asset=' + chainType + '&';
        if(checkMethod === 'number'){
            let blockQuery = ''
            blocklist.forEach(n => blockQuery += n + ',')
            console.log(blockQuery)
            query = query + 'blocklist=' + blockQuery.slice(0, -1)
        }
        else if (checkMethod === 'range'){
            console.log(blockrange)
            query = query + 'startblock=' + blockrange[0] + '&endblock=' + blockrange[1]
        }
        else {
            query = query + 'startdate=' + daterange[0] + '&enddate=' + daterange[1]
        }
        console.log(query)
        let result = await instance.get("/checkFBBlockTrans?" + query)
        if(result.status === 200) {
            let autosellTransactions = result.data[0]
            let monitorOnlyTransactions = result.data[1]
            return {autoSell: autosellTransactions, monitorOnly: monitorOnlyTransactions}
        }
        else {
            return false
        }
    }
    catch(err){
        console.log(err)
        return false
    }
}
export const executeAutosellTrades = async(tradelist: any[]) => {
    try {
        let result = await instance.post("/executeTrades", {tradeList: tradelist})
        console.log(result)
        return result
    }
    catch(err) {
        console.log(err)
        return false
    }
}
