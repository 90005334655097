import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import { Typography } from 'antd';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useMode } from 'src/hooks/ModeContext';
import moment from 'moment';
import serverApi from '../../api/server';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import generateSignature from '../../api/apiAuth'

const { Title, Text } = Typography;
function ExternalWalletTransactionTable() {
    const [transactionData, setTransactionData] = useState<any[]>([]);
    const [searchTXValue, setSearchTXValue] = useState('');
    const [searchTXResult, setSearchTXResult] = useState<any[]>([]);
    const [openAddWallet, setOpenAddWallet] = useState(false);
    const [updateWalletList, setUpdateWalletList] = useState(0);
    const [openFilter, setOpenFilter] = useState(false);
    const [filterName, setFilterName] = useState('');
    const [filterSource, setFilterSource] = useState('');
    const [filterDateStart, setFilterDateStart] = useState(null);
    const [filterDateEnd, setFilterDateEnd] = useState(null);
    const [filterVault, setFilterVault] = useState('');
    const [loading, setLoading] = useState(false);
    const [curTxIndex, setCurTxIndex] = useState();
    const [txData, setTxData] = useState();
    const [openTxInfo, setOpenTxInfo] = useState(false);
    const [state, action] = useMode()
    const getExternalWalletTransactionData = async() : Promise<any[]> => {
        try {
            let resultJSONData;
            // if(state.mode === 'sdm') {
            //     console.log(state.mode)
            // }


            const timeStamp = moment().seconds(0).milliseconds(0).toISOString().slice(0, -1) + "000Z"
            const query = state.mode.toUpperCase()
            const path = `/api/getExtTransactions?unit=${query}`

            const signature = generateSignature("GET", timeStamp, path, query, null) 

            const result = await serverApi.get('/getExtTransactions?unit=' + state.mode.toUpperCase(), {headers: {Authorization: signature}})
            console.log(result)
            resultJSONData = result.data
            console.log(resultJSONData)
            return resultJSONData
        }
        catch (err) {
            alert(err)
            return []
        }
    }
    useEffect(() => {
        //Filter options updated so apply all filters here
        //let result = transactions
        
        getExternalWalletTransactionData().then((res:any) => {
            setLoading(true)
            let result: any[] = res
            if(result) {
                if(searchTXValue) {
                    result = result.filter((i: any) => (i.Address).toUpperCase().includes(searchTXValue.toUpperCase()))
                }
                if(filterName) {
                    result = filterWithAsset(result, filterName);
                }
                if(filterDateStart && filterDateEnd) {
                    result = filterWithDateRange(result)
                    console.log(result)
                }
                setTransactionData(result);
                
            }
            setLoading(false)
        });
    }, [searchTXValue, state.mode, filterName, filterDateStart,filterDateEnd]);
    const filterWithAsset = (arr: any[], assetName: string) => {
        return arr.filter(item => item.Name === assetName);
    }
    const filterWithSource = (arr: any[], source: string) => {
        return arr.filter(item => item.source.name === source);
    }
    const filterWithDateRange = (arr: any[]) => {
        return filterDateStart && filterDateEnd? arr.filter(item => moment(item.Date).isSameOrAfter(filterDateStart, 'day') && moment(item.Date).isSameOrBefore(filterDateEnd)) : arr;
    }
    const handleSourceFilterChange = (event: any) => {
        setFilterSource(event.target.value)
    }
    const handleSearchTXAddress = (e:any) => {
        let inputValue = (e.target.value).trim()
        setSearchTXValue(inputValue)
        // let 
        // console.log(result)
        // setSearchTXResult(result)
    }
    const handleClickClearTransactionSearchInput = () => {
        setSearchTXValue('')
    }
    const handleNameFilterChange = (event:any) => {
        setFilterName(event.target.value)
    }
    const clearFilters = () => {
        setFilterVault('');
        setFilterName('')
        setFilterSource('')
        setFilterDateStart(null)
        setFilterDateEnd(null)
        // setTransactionData(transactions)
    }
    const closeFilterMenu = () => {
        setOpenFilter(false)
    }
    const openFilterMenu = () => {
        setOpenFilter(true)
    }
    const handleOpenTxInfoDialog = (row: any, index: any) => {
        setTxData(row)
        setCurTxIndex(index)
        setOpenTxInfo(true)
    }
    return (
        <div>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <Paper
                    component="form"
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 200, height: 36 ,marginBottom: 1, marginRight: 5 }}
                >
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        value={searchTXValue}
                        onChange={(e) => handleSearchTXAddress(e)}
                        placeholder="Search Address"
                        inputProps={{ 'aria-label': 'search client' }}
                        // startAdornment={<SearchIcon /> }
                        endAdornment={searchTXValue? 
                            <InputAdornment position="end">
                                <IconButton
                                    size='small'
                                    onClick={handleClickClearTransactionSearchInput}
                                    style={{borderRadius: 0}}
                                >
                                    <CloseIcon />
                                </IconButton>
                                </InputAdornment> : <SearchIcon />}
                            />
                        </Paper>
                        {openFilter? 
                            <Paper elevation={2} sx={{display: 'inline-flex', flexDirection: 'row', marginBottom: 5, minHeight: 50, padding: 2, paddingX: 3, paddingY: 1.5}}>
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    <FormControl sx={{ minWidth: 120, marginRight: 3, marginTop: 1 }} size="small">
                                        <InputLabel id="demo-select-small">Name</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={filterName}
                                            label="Name"
                                            onChange={handleNameFilterChange}
                                        >
                                            {transactionData && Array.from(new Set(transactionData.map(item => item.Name))).map(item =>  
                                                <MenuItem key={item} value={item}>{item}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </div>
                                {/* <div style={{display: 'flex', flexDirection: 'column'}}>
                                    <FormControl sx={{ minWidth: 120, marginRight: 3, marginTop: 1 }} size="small">
                                        <InputLabel id="demo-select-small">Source</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={filterSource}
                                            label="Source"
                                            onChange={handleSourceFilterChange}
                                        >
                                            {transactionData && Array.from(new Set(transactionData.map(tag => tag.source.name))).map(item => 
                                                <MenuItem key={item} value={item}>{item}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </div> */}
                                <div style={{display: 'flex', flexDirection: 'column', marginTop: 8}}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="From"
                                            value={filterDateStart}
                                            onChange={(newValue: any) => {
                                                setFilterDateStart(newValue);
                                            }}
                                            maxDate={filterDateEnd ? filterDateEnd : null}
                                            renderInput={(params) => <TextField {...params} size="small"/>}
                                        />
                                    </LocalizationProvider>
                                    <span style={{margin: 5, textAlign: 'center'}}></span>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="To"
                                            value={filterDateEnd}
                                            onChange={(newValue: any) => {
                                                setFilterDateEnd(newValue);
                                            }}
                                            minDate={filterDateStart ? filterDateStart : null}
                                            renderInput={(params) => <TextField {...params} size="small"/>}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <Button sx={{marginLeft: 5, height: 30, alignSelf: 'center'}} variant="text" size="small" onClick={() => clearFilters()} disabled={!(filterName || filterDateStart || filterDateEnd)}>clear filter</Button>
                                <IconButton
                                    aria-label="expand row"
                                    sx={{marginLeft: 5, height: 40, alignSelf: 'start'}}
                                    onClick={() => closeFilterMenu()}
                                >
                                    <CloseIcon/>
                                </IconButton>
                            </Paper> : <Button variant="text" size="small" onClick={() => openFilterMenu()}>open filter</Button>}
                        </div>
                        <div className='fireblockTransactionContainer'>
                            <TableContainer component={Paper} sx={{ maxHeight: 550 }}>
                                {loading ? <LinearProgress color="success"/> : null}
                                <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><Title level={3}>Date</Title></TableCell>
                                        <TableCell align="left"><Title level={3}>Tx Type</Title></TableCell>
                                        <TableCell align="left"><Title level={3}>Name</Title></TableCell>
                                        <TableCell align="left"><Title level={3}>Asset</Title></TableCell>
                                        <TableCell align="left"><Title level={3}>Address</Title></TableCell>
                                        <TableCell align="left"><Title level={3}>Amount</Title></TableCell>
                                        <TableCell align="left"><Title level={3}>Tx ID</Title></TableCell>
                                        <TableCell align="left"/>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {transactionData && transactionData
                                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={index} selected={curTxIndex === index}>
                                        <TableCell>{moment(row.Date).format('L')}</TableCell>
                                        <TableCell align='left'>{row.TransType}</TableCell>
                                        <TableCell align='left'>{row.Name}</TableCell>
                                        <TableCell align='left'>{row.Asset}</TableCell>
                                        <Tooltip title={row.Address} placement="top"><TableCell align='left'>{row.Address.substr(0, 7) + '...' + row.Address.substr(row.Address.length-7, row.Address.length)}</TableCell></Tooltip>
                                        <TableCell align='left'>{row.Amount}</TableCell>
                                        <TableCell align='left'>{row.TxID}</TableCell>
                                        <TableCell align='left'>
                                        {/* <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => handleOpenTxInfoDialog(row, index)}
                                        >
                                            <InfoIcon color="success"/>
                                        </IconButton> */}
                                        </TableCell>
                                    </TableRow>
                                    )
                                )}
                            </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
        </div>
    );
}

export default ExternalWalletTransactionTable;