import React, {useEffect, useState} from 'react';
import './Chain.css'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ReactLoading from 'react-loading';
import TransactionTableDialog from '../components/TransactionTableDialog';
import {getCurrentChainStatus, changeCurrentChainStatus, ChainMode, getCurrentBTCBlock, checkBTCTrades} from './utils'
import { useMode } from 'src/hooks/ModeContext';
function BTCChain(props: any) {
    const [state, action] = useMode()
    const [currentBlockHeight, setCurrentBlockHeight] = useState("746978")
    const [lastBlockChecked, setLastBlockChecked] = useState("")
    const [currentStatus, setCurrentStatus] = useState("Checking");
    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState("");
    const [warning, setWarning] = useState(false)
    const [warningMsg, setWarningMsg] = useState('')
    const [checkMethod, setCheckMethod] = useState("")
    // select blocks
    const [selectedBlocks, setSelectedBlocks] = useState<any>([]);
    const [inputBlock, setInputBlock] = useState('')
    // block range
    const [startBlock, setStartBlock] = useState('');
    const [endBlock, setEndBlock] = useState('');
    // date range
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    // transactions
    // const [loading, setLoading] = useState(false);
    const [autoSellTx, setAutoSellTx] = useState<any>([]);
    const [openAutosellTx, setOpenAutosellTx] = useState(false);
    const [monitorOnlyTx, setMonitorOnlyTx] = useState<any>([]);
    const [openMonitorOnlyTx, setOpenMonitorOnlyTx] = useState(false);
    const [tradeLoading, setTradeLoading] = useState(false);
    const [updated, setUpdated] = useState(0);
    useEffect(() => {
        getChainStatus()
        getBlocks()
    },[state])
    useEffect(() => {
        if(checkMethod === 'number') {
            onBlockNumbersSubmit()
        }else {
            onBlockRangeSubmit()
        }
    },[updated])
    useEffect(() => {
        getBlocks()
    },[currentStatus])
    const handleTradeUpdate = () => {
        let count = updated + 1
        setUpdated(count)
    }
    const getChainStatus = async () => {
        let status = await getCurrentChainStatus('btcMode')
        if(status) {
            setCurrentStatus(status)
        }
        else {
            setCurrentStatus("")
            setError(true)
            setErrorMsg("Can't get the current status of BTC service!")
        }
    }
    const getBlocks = async() => {
        let {lastChecked, latestBlock } = await getCurrentBTCBlock()
        setLastBlockChecked(lastChecked)
        setCurrentBlockHeight(latestBlock)
    }
    const changeChainStatus = async(newMode: ChainMode) => {
        let newStatus = newMode
        let res = await changeCurrentChainStatus('btcMode', newStatus)
        console.log(res)
        if(res){
            getChainStatus()
        }
    }
    const handleChangeBlockCheckMethod = (event: SelectChangeEvent) => {
        setCheckMethod(event.target.value as string);
    };
    const inputChange = (event: any) => {
        setInputBlock(event.target.value)
    };
    const handleSelectBlockKeyDown = ({key} : any) => {
        if(key === 'Enter') {
            let blockArr = [...selectedBlocks]
            if(blockArr.length >= 5) {
                setWarning(true)
                setWarningMsg("Please enter only 5 block numbers each time!")
            }
            else if(Number(inputBlock) > Number(currentBlockHeight)) {
                setError(true)
                setErrorMsg('Block number cannot be larger than the current block height!')
            }
            else {
                if(!blockArr.includes(inputBlock)){
                    blockArr.push(inputBlock)
                    setSelectedBlocks(blockArr)
                }
            }
            setInputBlock("")
        }
    }
    const handleDeleteBlockInput = (item: string) => {
        let blockArr = [...selectedBlocks]
        let position = blockArr.indexOf(item)
        blockArr.splice(position, 1)
        setSelectedBlocks(blockArr)
    }
    const rangeInputChange = (event: any, blockType: string) => {
        if(Number(event.target.value) > Number(currentBlockHeight)){
            setError(true)
            setErrorMsg('Block number cannot be larger than the current block height!')
        }
        if(blockType === 'start') {
            setStartBlock(event.target.value)
        }
        else {
            setEndBlock(event.target.value)
        }
    }
    const onBlockNumbersSubmit = async() => {
        setCurrentStatus("Checking")
        console.log(selectedBlocks)
        const result = await checkBTCTrades('number', selectedBlocks, [], [])
        console.log(result)
        if(result){
            setAutoSellTx(result.autoSell)
            setMonitorOnlyTx(result.monitorOnly)
        }
        getChainStatus()
    }
    const onBlockRangeSubmit = async() => {
        console.log([startBlock, endBlock])
        const result = await checkBTCTrades('range', [], [startBlock, endBlock], [])
        console.log(result)
        if(result){
            setAutoSellTx(result.autoSell)
            setMonitorOnlyTx(result.monitorOnly)
        }
        getChainStatus()
    }
    return (
        <div className='chainContainer'>
            <div className='detailContainer' style={{marginBottom: 10}}>
                <div style={{display: 'inline-flex', flexDirection: 'row'}}>
                    <div style={{marginRight: 12, fontWeight: 'bold', margin: 'auto'}}>Last Block Checked: </div>
                    <div style={{marginRight: 10, margin: 'auto'}}>{lastBlockChecked}</div>
                </div>
                <div className='blockDetail'>
                    <div style={{marginRight: 12, fontWeight: 'bold', margin: 'auto'}}>Current Block: </div>
                    <div style={{marginRight: 10, margin: 'auto'}}>{currentBlockHeight}</div>
                </div>
                <div style={{ width: '30%'}}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Check Blocks By</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={checkMethod}
                            label="Check Blocks By"
                            onChange={handleChangeBlockCheckMethod}
                        >
                            <MenuItem value={'number'}>Block Numbers</MenuItem>
                            <MenuItem value={'range'}>Block Range</MenuItem>
                            {/* <MenuItem value={'date'}>Date Range</MenuItem> */}
                        </Select>
                    </FormControl>
                </div>
            </div>
            {checkMethod === 'number' ? 
                <div style={{display: 'flex', flexDirection: 'row', alignContent: 'flex-start', marginBottom: 10}}>
                    <div style={{fontWeight: 'bold', margin: 'auto', marginLeft: 0, marginRight:12, width: 'max-content'}}>Selected blocks: </div>
                    <div style={{marginRight: 5}}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Selected Blocks"
                            type={"number"}
                            // multiline
                            value={inputBlock}
                            onChange={inputChange}
                            onKeyDown={handleSelectBlockKeyDown}
                            // multiline
                            InputProps={{
                                startAdornment: 
                                    selectedBlocks.map((item: string) => (
                                        <Chip
                                            key={item}
                                            label={item}
                                            sx={{marginRight: 1}}
                                            onDelete={() => handleDeleteBlockInput(item)}
                                        />
                                    ))
                            }}
                        />
                    </div>
                    <Button variant="contained" color="success" disabled={selectedBlocks.length === 0} onClick={onBlockNumbersSubmit}>Check</Button>
                </div>
            : null}
            {checkMethod === 'range' ? 
            <div style={{display: 'flex', flexDirection: 'row', alignContent: 'flex-start', marginBottom: 10}}>
                <div style={{fontWeight: 'bold', margin: 'auto', marginLeft: 0, marginRight:12, width: 'max-content'}}>Start block: </div>
                <div>
                    <TextField label="start block" id="outlined-size-small" value={startBlock} size="small"
                        onChange={e => rangeInputChange(e, 'start')}
                    />
                </div>
                <div style={{fontWeight: 'bold', margin: 'auto', marginLeft: 12, marginRight:12, width: 'max-content'}}>End block: </div>
                <div style={{marginRight: 5}}>
                    <TextField label="end block" id="outlined-size-small" placeholder={currentBlockHeight} value={endBlock} size="small"
                        onChange={e => rangeInputChange(e, 'end')}
                    />
                </div>
                <Button variant="contained" color="success" 
                    disabled={currentStatus == 'Checking' || !startBlock || Number(startBlock) > Number(endBlock) || Number(endBlock) > Number(currentBlockHeight) || Number(startBlock) > Number(currentBlockHeight)}
                    onClick={onBlockRangeSubmit}
                >Check</Button>
            </div> 
            : null}
            {checkMethod === 'date' ? 
            <div style={{display: 'flex', flexDirection: 'row', alignContent: 'flex-start', marginBottom: 10}}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="From"
                        value={startDate}
                        onChange={(newValue: any) => {
                            setStartDate(newValue);
                        }}
                        maxDate={endDate ? endDate : null}
                        renderInput={(params) => <TextField {...params} size="small"/>}
                    />
                </LocalizationProvider>
                <span style={{margin: 5, textAlign: 'center'}}></span>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="To"
                        value={endDate}
                        onChange={(newValue: any) => {
                            setEndDate(newValue);
                        }}
                        minDate={startDate ? startDate : null}
                        renderInput={(params) => <TextField {...params} size="small"/>}
                    />
                </LocalizationProvider>
                <span style={{margin: 5, textAlign: 'center'}}></span>
                <Button variant="contained" color="success" disabled={!startDate || !endDate}>Check</Button>
            </div>
            : null}
            {currentStatus == 'Online'?
            <>
                <div className='serviceButton'>
                    <IconButton aria-label="Running" onClick={() => changeChainStatus('Offline')}>
                        <PauseCircleOutlineIcon sx={{fontSize: '200px'}}/>
                    </IconButton>
                        
                </div>
                <div className='serviceButton'>
                    <Chip color="success" label='Running' variant='outlined' sx={{width: '150px', height: '40px', fontWeight: 'bold', fontSize: '20px'}}/>
                </div>
            </>
            :currentStatus == 'Offline'? 
            <>
                <div className='serviceButton'>
                    <IconButton aria-label="onHalt" onClick={() => changeChainStatus('Online')}>
                        <PlayCircleOutlineIcon sx={{fontSize: '200px'}}/>
                    </IconButton>
                        
                </div>
                <div className='serviceButton'>
                    <Chip color="error" label='On Halt' variant='outlined' sx={{width: '150px', height: '40px', fontWeight: 'bold', fontSize: '20px'}}/>
                </div>
            </> 
            :currentStatus == 'Checking'? 
            <>
                <div className='serviceButton' style={{marginTop: 10, marginBottom: 10}}>
                    <ReactLoading type={'spokes'} color={'grey'} height={200} width={200} />
                </div>
                <div className='serviceButton'>
                    <Chip label='Checking Blocks' sx={{width: '150px', height: '40px', fontWeight: 'bold', fontSize: '15px'}}/>
                </div>
            </>
            :<>
                <div className='serviceButton'>
                    <IconButton aria-label="networkError" onClick={() => getChainStatus()}>
                        <ErrorOutlineIcon sx={{fontSize: '200px'}}/>
                    </IconButton>
                        
                </div>
                <div className='serviceButton'>
                    <Chip color="warning" label='Network Error' sx={{width: '150px', height: '40px', fontWeight: 'bold', fontSize: '15px'}}/>
                </div>
            </>}
            <div className='transactionButton'>
                {autoSellTx.length > 0 ? <Button variant="outlined" onClick={() => setOpenAutosellTx(true)}>View Auto Sell Deposit Transactions</Button> : null}
                {monitorOnlyTx.length > 0 ? <Button variant="outlined" sx={{marginTop: 2}} onClick={() => setOpenMonitorOnlyTx(true)}>View Monitor Only Deposit Transactions</Button> :null}
            </div>
            <TransactionTableDialog data={autoSellTx} open={openAutosellTx} handleClose={() => setOpenAutosellTx(false)} tableName={"Auto Sell Deposits"} chain={'BTC'} txType={"autosell"} setTradeLoading={setTradeLoading} tradeLoading={tradeLoading} handleTradeUpdate={handleTradeUpdate}/>
            <TransactionTableDialog data={monitorOnlyTx} open={openMonitorOnlyTx} handleClose={() => setOpenMonitorOnlyTx(false)} tableName={"Monitor Only Deposits"} chain={'BTC'} txType={"monitor"}/>
            <Snackbar open={error} autoHideDuration={6000} onClose={() => setError(false)}>
                <Alert severity="error">{errorMsg}</Alert>
            </Snackbar>
            <Snackbar open={warning} autoHideDuration={6000} onClose={() => setError(false)}>
                <Alert severity="warning">{warningMsg}</Alert>
            </Snackbar>
        </div>
    );
}

export default BTCChain;