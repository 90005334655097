import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import './EditWalletModal.css'
import { Typography } from 'antd';
import { validate } from 'bitcoin-address-validation';
import serverApi from '../../api/server';
import generateSignature from '../../api/apiAuth';
import Web3 from 'web3';
import moment from 'moment';
import AutoSellWarningDialog from './AutoSellWarningDialog';
const {Text} = Typography;

const isAxmed = process.env.REACT_APP_AXMED === 'true' ? true:false
interface Asset {
    AssetPair: string;
    AssetWallet: string;
    Fee: string;
    Mode: string;
    Notes: string;
    WalletAddr: string;
    LiqProvider: string;
    Description: string;
    OrderType: string;
    Threshold?: string;
    LimitPrice: string;
    NotificationEmail:string;
}
const assetPairs = [
    {
        value: 'BTCUSD',
        label: 'BTC-USD'
    },
    {
        value: 'BTCCAD',
        label: 'BTC-CAD'
    },
    {
        value: 'BTCEUR',
        label: 'BTC-EUR'
    },
    {
        value: 'ETHUSD',
        label: 'ETH-USD'
    },
    {
        value: 'ETHCAD',
        label: 'ETH-CAD'
    },
    {
        value: 'ETHEUR',
        label: 'ETH-EUR'
    },
    {
        value: 'USDTUSD',
        label: 'USDT-USD'
    },
    {
        value: 'USDTCAD',
        label: 'USDT-CAD'
    },
    {
        value: 'USDTEUR',
        label: 'USDT-EUR'
    },
    {
        value: 'USDCUSD',
        label: 'USDC-USD'
    },
    {
        value: 'USDCCAD',
        label: 'USDC-CAD'
    },
    {
        value: 'USDCEUR',
        label: 'USDC-EUR'
    },
    {
        value: 'DAIUSD',
        label: 'DAI-USD'
    },
    {
        value: 'DAICAD',
        label: 'DAI-CAD'
    },
    {
        value: 'DAIEUR',
        label: 'DAI-EUR'
    },
    // {
    //     value: 'WETHUSD',
    //     label: 'WETH-USD'
    // },
    // {
    //     value: 'WETHCAD',
    //     label: 'WETH-CAD'
    // },
    // {
    //     value: 'WETHEUR',
    //     label: 'WETH-EUR'
    // },
    {
        value: 'WBTCUSD',
        label: 'WBTC-USD'
    },
    {
        value: 'WBTCCAD',
        label: 'WBTC-CAD'
    },
    {
        value: 'WBTCEUR',
        label: 'WBTC-EUR'
    },
]
const axmedAssetPairs = [
    {
        value: 'BTCUSDT',
        label: 'BTC-USDT'
    },{
        value: 'BTCUSDC',
        label: 'BTC-USDC'
    },
    {
        value: 'ETHUSDT',
        label: 'ETH-USDT'
    },
    {
        value: 'ETHUSDC',
        label: 'ETH-USDC'
    },
    {
        value: 'USDTUSDC',
        label: 'USDTUSDC'
    },
    {
        value: 'USDCUSDT',
        label: 'USDC-USDT'
    },
    {
        value: 'DAIUSDT',
        label: 'DAI-USDT'
    },
    {
        value: 'DAIUSDC',
        label: 'DAI-USDC'
    },
    // {
    //     value: 'WETHUSDT',
    //     label: 'WETH-USDT'
    // },
    // {
    //     value: 'WETHUSDC',
    //     label: 'WETH-USDC'
    // },
    {
        value: 'WBTCUSDT',
        label: 'WBTC-USDT'
    },
    {
        value: 'WBTCUSDC',
        label: 'WBTC-USDC'
    },
]
export default function EditExternalWalletModal({openDialog, closeDialog, row, asset, onHandleWalletDetailChange, onCancelWalletDetailChange, updateWalletList, setUpdateWalletList} : any) {
    const [curAsset, setCurAsset] = React.useState<Asset>();
    const [openAutosellWarning, setOpenAutosellWarning] =  React.useState(false);
    React.useEffect(() => {
        setCurAsset(asset)
        if(!openDialog) return function cleanup() {
            console.log('clean up modal')
            setCurAsset(undefined)
        };
    }, [asset, openDialog])
    const handleChange = (type: any, event: any) => {
        let updateAsset = Object.assign({}, curAsset)
        if(type === 'assetpair') {
            updateAsset.AssetPair = event.target.value
            setCurAsset(updateAsset)
        }
        else if(type === 'mode') {
            updateAsset.Mode = event.target.value
            if(updateAsset.Mode === 'Autosell'){
                setOpenAutosellWarning(true)
            } else {
                setCurAsset(updateAsset)
            }

        }
        else if(type === 'fee') {
            updateAsset.Fee = event.target.value
            setCurAsset(updateAsset)
        }
        else if(type === 'notes') {
            updateAsset.Notes = event.target.value
            setCurAsset(updateAsset)
        }
        else if(type === 'liqprovider') {
            updateAsset.LiqProvider = event.target.value
            setCurAsset(updateAsset)
        }
        else if(type === 'type') {
            updateAsset.AssetWallet = event.target.value
            setCurAsset(updateAsset)
        }
        else if(type === 'description') {
            updateAsset.Description = event.target.value
            setCurAsset(updateAsset)
        }
        else if(type === 'limitprice') {
            updateAsset.LimitPrice = event.target.value
            setCurAsset(updateAsset)
        }
        else if(type === 'ordertype') {
            updateAsset.OrderType = event.target.value
            setCurAsset(updateAsset)
        }
        else if (type === 'notificationemail') {
            updateAsset.NotificationEmail = event.target.value
            setCurAsset(updateAsset)
        }
        else if (type === 'threshold') {
            updateAsset.Threshold = event.target.value
            setCurAsset(updateAsset)
        }
    }
    // console.log(curAsset);
    const isValidEthAddress = (adr: any) => {
        try {
            const web3 = new Web3()
            web3.utils.toChecksumAddress(adr)
            return true
        } catch (e) {
            return false
        }
    }
    
    const getWalletType = (address: string) => {
        let isBTC = validate(address)
        let isETH = isValidEthAddress(address)
        if(isBTC) {
            return 'BTC'
        } 
        else if(isETH) {
            return 'ETH'
        }
    }
    const handleWalletDetailChange = async() => {
        try {
            if(curAsset) {
                //TODO: ADD ordertype AND limitprice (can be blank)to params
                const jsonData = {
                    pair: curAsset.AssetPair,
                    asset: curAsset.AssetWallet,
                    description: curAsset.Description,
                    notes: curAsset.Notes,
                    address: curAsset.WalletAddr,
                    mode: curAsset.Mode,
                    fee: curAsset.Fee,
                    liqprovider: curAsset.LiqProvider,
                    ordertype: curAsset.OrderType,
                    limitprice: curAsset.LimitPrice,
                    notificationEmail: curAsset.NotificationEmail,
                    threshold: curAsset.Threshold
                };
                console.log(jsonData)
                // create sig
                const timeStamp = moment().seconds(0).milliseconds(0).toISOString().slice(0, -1) + "000Z"
                const path = '/api/updateExternalWallet' 
    
                const signature = generateSignature("POST", timeStamp, path, null, jsonData) 
    
                const res = await serverApi.post(`/updateExternalWallet`, jsonData, {headers: {Authorization: signature}})

                console.log(res)
                if(res.data == 'Update successful') {
                    let counter = updateWalletList + 1;
                    closeDialog()
                    setUpdateWalletList(counter)
                }
            }
        }
        catch (err) {
            alert(err)
        }
    }
    const cancelChangingWalletMode = () => {
        setOpenAutosellWarning(false)
    }
    const confirmChangingWalletMode = () => {
        let updateAsset = Object.assign({}, curAsset)
        updateAsset.Mode = 'Autosell'
        setCurAsset(updateAsset)
        setOpenAutosellWarning(false)
    }
    if(!curAsset) {
        return null
    }
    return (
    <div>
        <Dialog open={openDialog} onClose={() => onCancelWalletDetailChange(row, asset)} fullWidth>
            <DialogTitle>Edit Wallet Details</DialogTitle>
            <DialogContent>
                <div className='dialogRow'>
                    <Text className='label' strong>Liquidity Provider: </Text>
                    <TextField
                        select
                        value={curAsset?.LiqProvider ? curAsset?.LiqProvider : ''}
                        onChange={(e) => handleChange('liqprovider', e)}
                        variant="standard"
                        disabled
                    >
                        <MenuItem value={'DVCHAIN'}>DV Chain</MenuItem>
                        <MenuItem value={'B2C2'}>B2C2</MenuItem>
                    </TextField>
                </div>
                <div className='dialogRow'>
                    <Text className='label' strong>Asset Pair: </Text>
                    <TextField
                        select
                        value={curAsset.AssetPair}
                        onChange={(e) => handleChange('assetpair', e)}
                        variant="standard"
                    >
                        {(isAxmed? axmedAssetPairs : assetPairs).filter(pair => pair.label.split("-")[0]  == curAsset.AssetWallet).map(option => 
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>)}
                    </TextField>
                </div>
                {/* <div className='dialogRow'>
                    <Text className='label' strong>Wallet Type: </Text>
                    <TextField
                        select
                        value={curAsset?.AssetWallet ? curAsset?.AssetWallet : ''}
                        onChange={(e) => handleChange('type', e)}
                        variant="standard"
                    >
                        <MenuItem value={'BTC_TEST'}>
                            BTC_TEST
                        </MenuItem>
                        <MenuItem value={'ETH_TEST'}>
                            ETH_TEST
                        </MenuItem>
                    </TextField>
                </div> */}
                <div className='dialogRow'>
                    <Text className='label' strong>Wallet Mode: </Text>
                    <TextField
                        select
                        value={curAsset.Mode}
                        onChange={(e) => handleChange('mode', e)}
                        variant="standard"
                    >
                        <MenuItem value={'Autosell'}>
                            Auto sell
                        </MenuItem>
                        <MenuItem value={'MonitorOnly'}>
                            Monitor Only
                        </MenuItem>
                    </TextField>
                </div>
                {curAsset.Mode === 'Autosell' ?
                <>
                    <div className='dialogRow'>
                        <Text className='label' strong>Order Type: </Text>
                        <TextField
                            select
                            value={curAsset.OrderType}
                            onChange={(e) => handleChange('ordertype', e)}
                            variant="standard"
                            disabled
                        >
                            <MenuItem value={'Market'}>
                                Market
                            </MenuItem>
                            <MenuItem value={'Limit'}>
                                Limit
                            </MenuItem>
                        </TextField>
                    </div>
                    <div className='dialogRow'>
                    <Text className='label' strong>Threshold: </Text>
                    <TextField 
                        id="standard-basic" 
                        variant="standard" 
                        type="number" 
                        // defaultValue={0.05}
                        value={curAsset?.Threshold} 
                        onChange={(e) => handleChange('threshold', e)}/>
                </div>
                    {curAsset.OrderType === 'Limit' ?
                    <div className='dialogRow'>
                        <Text className='label' strong>Limit Price: </Text>
                        <TextField 
                            id="standard-basic" 
                            variant="standard" 
                            type="number" 
                            value={curAsset.LimitPrice} 
                            onChange={(e) => handleChange('limitprice', e)}/>
                    </div> : null}
                </> : null}
                <div className='dialogRow'>
                    <Text className='label' strong>Wallet Fee (in decimal form): </Text>
                    <TextField 
                        id="standard-basic" 
                        variant="standard" 
                        type="number" 
                        // defaultValue={0.05}
                        value={curAsset?.Fee} 
                        onChange={(e) => handleChange('fee', e)}/>
                </div>
                <div className='dialogRow'>
                    <Text className='label' strong>Description: </Text>
                    <TextField
                        id="outlined-multiline-static"
                        // label="Wallet Address"
                        variant="standard" 
                        sx={{width: '60%'}}
                        value={curAsset?.Description ? curAsset?.Description : ''}
                        onChange={(e) => handleChange('description', e)}
                    />
                </div>
                <div className='dialogRow'>
                    <TextField
                        id="outlined-multiline-static"
                        label="Notes"
                        sx={{width: '100%'}}
                        multiline
                        rows={4}
                        defaultValue={curAsset.Notes && curAsset.Notes !== "\"\"" ? curAsset.Notes : ""}
                        onChange={(e) => handleChange('notes', e)}
                    />
                </div>
                <div className='dialogRow'>
                    <TextField
                        id="outlined-multiline-static"
                        label="Notification Email"
                        sx={{width: '100%'}}
                        defaultValue={curAsset.NotificationEmail ? curAsset.NotificationEmail : ""}
                        onChange={(e) => handleChange('notificationemail', e)}
                    />
                </div>
            </DialogContent>
            <DialogActions sx={{marginRight: '15px'}}>
                <Button onClick={() => onCancelWalletDetailChange(row, asset)} color="error">Cancel</Button>
                <Button
                    onClick={() => handleWalletDetailChange()} 
                    variant="contained" color="success"
                    disabled={!curAsset || !curAsset.AssetPair || !curAsset.AssetWallet || !curAsset.Mode || !curAsset.WalletAddr || !curAsset.Description || !curAsset.LiqProvider}
                >Save</Button>
            </DialogActions>
        </Dialog>
        <AutoSellWarningDialog 
            open={openAutosellWarning}
            closeDialog={cancelChangingWalletMode}
            confirmDialog={confirmChangingWalletMode}
        />
    </div>
    );
}
  
