import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import './EditWalletModal.css'
import { Typography } from 'antd';
import serverApi from '../../api/server';
import generateSignature from '../../api/apiAuth';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import JSONPretty from 'react-json-pretty';
import copy from 'copy-to-clipboard';
import { validate, getAddressInfo } from 'bitcoin-address-validation';
import Web3 from 'web3'
import moment from 'moment';

// var WAValidator = require('wallet-address-validator');
const {Text} = Typography;
interface Wallet {
    type: string;
    quantity: number;
}
interface WalletAddress {
    address: string
}
const assetPairs = [
    {
        value: 'BTC',
        label: 'BTC'
    },
    {
        value: 'ETH',
        label: 'ETH'
    },
    {
        value: 'USDC',
        label: 'USDC'
    },
    {
        value: 'USDT',
        label: 'USDT'
    },
]
const assetPairs_dev = [
    {
        value: 'BTC_TEST',
        label: 'BTC_TEST'
    },
    {
        value: 'ETH_TEST',
        label: 'ETH_TEST'
    },
    {
        value: 'USDC_TEST',
        label: 'USDC_TEST'
    },
    {
        value: 'USDT_TEST',
        label: 'USDT_TEST'
    },
]
export default function BulkCreateWalletModal({mode, openDialog, closeDialog, row, updateWalletList, setUpdateWalletList} : any) {
    const [curWallet, setCurWallet] = React.useState<Wallet>();
    const [loading, setLoading] = React.useState(false);
    const [walletList, setWalletList] = React.useState([]);
    const handleChange = (type: any, event: any) => {
        console.log(type + ': ' + event.target.value)
        let updateWallet = Object.assign({}, curWallet)
        if(type === 'walletType') {
            updateWallet.type = event.target.value
        }
        // else if(type === 'type') {
        //     updateAsset.AssetWallet = event.target.value
        // }
        else if(type === 'quantity') {
            updateWallet.quantity = event.target.value
        }
        setCurWallet(updateWallet)
    }

    const onHandleCreateNewWallet = async() => {
        console.log(row)
        console.log({
            AccountId: row.AcctId,
            unit: mode,
            assetId: curWallet?.type,
            numWallets: curWallet?.quantity
        })
        setLoading(true)
        try {
            // generate sig

            const timeStamp = moment().seconds(0).milliseconds(0).toISOString().slice(0, -1) + "000Z"
            const path = 'api/createFBWallets' 
            const data = {
                AccountId: row.AcctId,
                unit: mode,
                assetId: process.env.NODE_ENV === 'development'? 'BTC_TEST' : 'BTC',
                numWallets: curWallet?.quantity
            }

            const signature = generateSignature("POST", timeStamp, path, null, data) 

            const result = await serverApi.post('/createFBWallets', data, {headers: {Authorization: signature}})
            // console.log(result)
            console.log(result.data)
            
            if(result.status === 200) {
                // let formattedData = result.data.map((w: any) => {
                //     console.log(w)
                //     JSON.parse(w)
                // })
                // console.log(formattedData)
                setWalletList(result.data)
                setCurWallet({} as Wallet)

                let counter = updateWalletList + 1;
                setLoading(false)
                setUpdateWalletList(counter)
                // setTimeout(() => {
                    
                    
                //     closeDialog()
                //     setCurWallet({} as Wallet)
                // }, 5000)
                
                
                //TODO: DISPLAY NEWLY CREATED WALLET LIST
            }
            else {

                alert(result.data)
            }
        }
        catch (err) {
            setLoading(false)
            alert(err)
            return
        }
    }
    // console.log(curAsset);
    
    // if(!curAsset) {
    //     return null
    // }
    const closeWalletList = () => {
        let counter = updateWalletList + 1;
        setUpdateWalletList(counter)
        setWalletList([])
        closeDialog()
    }
    const copyWalletAddresses = () => {
        let addressListToClipboard = walletList.map((wallet: WalletAddress) => wallet.address)
        copy(addressListToClipboard.join(",\n"))
    }
    return (
    <div>
        <Dialog open={openDialog} onClose={() => closeDialog()} fullWidth>
            {walletList.length === 0? 
                <>
                <DialogTitle>New Wallet Details</DialogTitle>
                <DialogContent>
                    {/* <div className='dialogRow'>
                        <Text className='label' strong>Liquidity Provider: </Text>
                        <TextField
                            select
                            disabled
                            value={curAsset?.LiqProvider ? curAsset?.LiqProvider : ''}
                            onChange={(e) => handleChange('liqprovider', e)}
                            variant="standard"
                        >
                            <MenuItem value={'DVCHAIN'}>DV Chain</MenuItem>
                            <MenuItem value={'B2C2'}>B2C2</MenuItem>
                        </TextField>
                    </div> */}
                    <div className='dialogRow'>
                        <Text className='label' strong>Wallet Type: </Text>
                        <TextField
                            select
                            value={process.env.NODE_ENV === 'development'? 'BTC_TEST' : 'BTC'}
                            onChange={(e) => handleChange('walletType', e)}
                            variant="standard"
                            disabled
                        >
                            {process.env.NODE_ENV === 'development'? 
                            assetPairs_dev.map(option => 
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>)
                            :assetPairs.map(option => 
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>)}
                        </TextField>
                    </div>
                    <div className='dialogRow'>
                        <Text className='label' strong>Number of Wallets to Create: </Text>
                        <TextField 
                            id="standard-basic" 
                            variant="standard" 
                            type="number" 
                            value={curWallet?.quantity} 
                            onChange={(e) => handleChange('quantity', e)}
                            InputProps={{
                                inputProps: { min: 1},
                            }}
                        />
                    </div>
                </DialogContent>
                <DialogActions sx={{marginRight: '15px'}}>
                    <Button onClick={() => closeDialog()} color="error">Cancel</Button>
                    {loading? 
                    <LoadingButton
                        loading
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="outlined"
                    >
                        Create
                    </LoadingButton>
                    :<Button onClick={() => onHandleCreateNewWallet()} variant="contained" color="success" 
                    disabled={!curWallet || !curWallet.quantity}>Create</Button>}
                </DialogActions>
            </>
            :<>
                <DialogTitle id="alert-dialog-title">
                    {"New Wallet Addresses"}
                </DialogTitle>
                <DialogContent className='transactionDetailsContainer'>
                <JSONPretty id="json-pretty" data={walletList}></JSONPretty>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => copyWalletAddresses()}>COPY ALL</Button>
                    <Button onClick={() => closeWalletList()}>close</Button>
                </DialogActions>
            </>}
        </Dialog>
    </div>
    );
}

