import React from 'react';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import { Typography } from 'antd';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import copy from 'copy-to-clipboard';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';

const { Text } = Typography;
function FireblocksAutosell({data} : any) {
    const tableHeader = ['Unit', 'Client', 'Asset Pair', 'Wallet Type', 'Address',  'Liquidity Provider']
    const doc = new jsPDF('landscape')
    
    const downloadAuditReport = () => {
        const formattedData = data.map((row: any, index: number) => [row.Unit, row.ClientName, row.AssetPair, row.AssetWallet, row.WalletAddr, row.LiqProvider])
        console.log(formattedData)
        doc.text('Fireblocks Autosell wallets', 14, 16)
        autoTable(doc, { html: '#my-table' })
        autoTable(doc, {
            head: [tableHeader],
            body: formattedData,
            tableWidth: 'wrap',
            horizontalPageBreak: true,
            horizontalPageBreakRepeat: tableHeader[0],
        })
        doc.save('fireblocks-autosell-wallets.pdf')
    }
    // const copyToClipboard = (walletAddress: string) => {
    //     copy(walletAddress)
    // }
    return (
        <div style={{ flexDirection: 'column'}}>
            <Button style={{justifySelf: 'flex-end'}} disabled={data.length === 0} startIcon={<FileDownloadIcon />} onClick={() => downloadAuditReport()}>
                Download Audit report
            </Button>
            <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
                {/* {loading ? <LinearProgress color="success"/> : null} */}
                <Table stickyHeader aria-label="collapsible table" size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell><Text strong>Unit</Text></TableCell>
                            <TableCell><Text strong>Client</Text></TableCell>
                            <TableCell><Text strong>Asset Pair</Text></TableCell>
                            <TableCell><Text strong>Wallet Type</Text></TableCell>
                            <TableCell><Text strong>Address</Text></TableCell>
                            {/* <TableCell><Text strong>Decription</Text></TableCell> */}
                            {/* <TableCell><Text strong>Notes</Text></TableCell> */}
                            <TableCell><Text strong>Mode</Text></TableCell>
                            {/* <TableCell><Text strong>Threshold</Text></TableCell> */}
                            {/* <TableCell><Text strong>Fee</Text></TableCell> */}
                            <TableCell><Text strong>Liquidity Provider</Text></TableCell>
                            <TableCell />
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((asset: any, index: number) => (
                        <TableRow key={index}>
                            <TableCell component="th" scope="row">
                                    {asset.Unit}
                            </TableCell>
                            <TableCell>{asset.ClientName}</TableCell>
                            <TableCell>{asset.AssetPair}</TableCell>
                            <TableCell>{asset.AssetWallet}</TableCell>
                            <TableCell>{asset.WalletAddr}</TableCell>
                            {/* <TableCell>{asset.Description}</TableCell> */}
                            {/* <TableCell>{asset.Notes}</TableCell> */}
                            {/* <Tooltip title={asset.WalletAddr} placement="top"><TableCell>
                                <Button variant="text" onClick={() => copyToClipboard(asset.WalletAddr)}>{asset.WalletAddr.substr(0, 7) + '...' + asset.WalletAddr.substr(asset.WalletAddr.length-7, asset.WalletAddr.length)}</Button>
                            </TableCell></Tooltip> */}
                            {/* <Tooltip title={asset.Description} placement="top"><TableCell><span style={{ whiteSpace: 'nowrap', overflow: 'hidden', width: '150px', display: 'block' }}>{asset.Description}</span></TableCell></Tooltip> */}
                            {/* <Tooltip title={asset.Notes && asset.Notes !== "\"\""? asset.Notes : ''} placement="top"><TableCell><span style={{ whiteSpace: 'nowrap', overflow: 'hidden', width: '150px', display: 'block' }}>{asset.Notes && asset.Notes !== "\"\""? asset.Notes : null}</span></TableCell></Tooltip> */}
                            <TableCell>{asset.Mode}</TableCell>
                            {/* <TableCell>{asset.Threshold? asset.Threshold: 0}</TableCell> */}
                            {/* <TableCell>{asset.Fee}</TableCell> */}
                            <TableCell>{asset.LiqProvider}</TableCell>
                        </TableRow>))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default FireblocksAutosell;