import { createStore, createHook } from 'react-sweet-state';

const Store = createStore({
    // value of the store on initialisation
    initialState: {
        mode: 'sdm',
    },
    // actions that trigger store mutation
    actions: {
        changeMode:
            (data) =>
            ({ setState, getState }) => {
            // mutate state synchronously
            setState({
                mode: data
            });
            },
    },
    // optional, mostly used for easy debugging
    name: 'mode',
});

export const useMode = createHook(Store);

