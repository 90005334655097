import React, {createContext} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const auth0_domain = process.env.REACT_APP_AUTH0_DOMAIN?process.env.REACT_APP_AUTH0_DOMAIN: '';
const auth0_client_id = process.env.REACT_APP_AUTH0_CLIENT_ID?process.env.REACT_APP_AUTH0_CLIENT_ID: ''
root.render(
  <React.StrictMode>
    <Auth0Provider
        domain={auth0_domain}
        clientId={auth0_client_id}
        redirectUri={window.location.origin}
        audience={process.env.REACT_APP_AUTH0_AUDIENCE}
        //scope="read:current_user update:current_user_metadata"
        // onRedirectCallback={onRedirectCallback}
        useRefreshTokens
        cacheLocation="localstorage"
      >
        <App />
    </Auth0Provider>
    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
