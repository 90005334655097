import React, {useEffect, useState} from 'react';
import './Chain.css'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import ReactLoading from 'react-loading';
import Snackbar from '@mui/material/Snackbar';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import TransactionTableDialog from '../components/TransactionTableDialog';
import {transactionResult, getCurrentChainStatus, changeCurrentChainStatus, ChainMode, getCurrentBTCBlock, getCurrentETHBlock, checkFBTrades} from './utils'
import { useMode } from 'src/hooks/ModeContext';
function Fireblocks(props : any) {
    const [state, action] = useMode()
    const [currentStatus, setCurrentStatus] = useState("");
    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState("");
    const [warning, setWarning] = useState(false)
    const [warningMsg, setWarningMsg] = useState('')
    // BTC
    const [lastBlockCheckedBTC, setLastBlockCheckedBTC] = useState("");
    const [currentBlockHeightBTC, setCurrentBlockHeightBTC] = useState("");
    const [checkMethod, setCheckMethod] = useState("")
    // ETH
    const [lastBlockCheckedETH, setLastBlockCheckedETH] = useState("");
    const [currentBlockHeightETH, setCurrentBlockHeightETH] = useState("");
    const [selectedChain, setSelectedChain] = useState("BTC");
     // select blocks
    const [selectedBlocks, setSelectedBlocks] = useState<any>([]);
    const [inputBlock, setInputBlock] = useState('')
    // block range
    const [startBlock, setStartBlock] = useState('');
    const [endBlock, setEndBlock] = useState('');
    // TX
    const [autoSellTx, setAutoSellTx] = useState<any>([]);
    const [openAutosellTx, setOpenAutosellTx] = useState(false);
    const [monitorOnlyTx, setMonitorOnlyTx] = useState<any>([]);
    const [openMonitorOnlyTx, setOpenMonitorOnlyTx] = useState(false);
    const [tradeLoading, setTradeLoading] = useState(false);
    const [updated, setUpdated] = useState(0);
    useEffect(() => {
        getChainStatus()
        getBTCBlocks()
        getETHBlocks()
    },[state])
    useEffect(() => {
        getBTCBlocks()
        getETHBlocks()
    },[currentStatus])
    useEffect(() => {
        if(checkMethod === 'number') {
            onBlockNumbersSubmit()
        }else {
            onBlockRangeSubmit()
        }
    },[updated])
    const handleTradeUpdate = () => {
        let count = updated + 1
        setUpdated(count)
    }
    const getChainStatus = async () => {
        let status = await getCurrentChainStatus('fireblocksMode')
        if(status) {
            setCurrentStatus(status)
        }
        else {
            setCurrentStatus("")
            setError(true)
            setErrorMsg("Can't get the current status of fireblocks service!")
        }
    }
    const changeChainStatus = async(newMode: ChainMode) => {
        let newStatus = newMode
        let res = await changeCurrentChainStatus('fireblocksMode', newStatus)
        console.log(res)
        if(res){
            getChainStatus()
        }
    }
    const handleChangeBlockCheckMethod = (event: SelectChangeEvent) => {
        setCheckMethod(event.target.value as string);
    };
    const inputChange = (event: any) => {
        setInputBlock(event.target.value)
    };
    const handleSelectBlockKeyDown = ({key} : any) => {
        if(key === 'Enter') {
            let blockArr = [...selectedBlocks]
            if(blockArr.length >= 5) {
                setWarning(true)
                setWarningMsg("Please enter only 5 block numbers each time!")
            }
            else if(selectedChain==='BTC' && Number(inputBlock) > Number(currentBlockHeightBTC)) {
                setError(true)
                setErrorMsg('Block number cannot be larger than the current block height!')
            }
            else if(selectedChain==='ETH' && Number(inputBlock) > Number(currentBlockHeightETH)) {
                setError(true)
                setErrorMsg('Block number cannot be larger than the current block height!')
            }
            else {
                if(!blockArr.includes(inputBlock)){
                    blockArr.push(inputBlock)
                    setSelectedBlocks(blockArr)
                }
            }
            setInputBlock("")
        }
    }
    const handleDeleteBlockInput = (item: string) => {
        let blockArr = [...selectedBlocks]
        let position = blockArr.indexOf(item)
        blockArr.splice(position, 1)
        setSelectedBlocks(blockArr)
    }
    const getBTCBlocks = async() => {
        let {lastChecked, latestBlock } = await getCurrentBTCBlock()
        setLastBlockCheckedBTC(lastChecked)
        setCurrentBlockHeightBTC(latestBlock)
    }
    const getETHBlocks = async() => {
        let {lastChecked, latestBlock } = await getCurrentETHBlock()
        setLastBlockCheckedETH(lastChecked)
        setCurrentBlockHeightETH(latestBlock)
    }
    const handleSelectChainChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedChain((event.target as HTMLInputElement).value);
    }
    
    const onBlockNumbersSubmit = async() => {
        setCurrentStatus("Checking")
        console.log(selectedBlocks)
        let result: transactionResult;
        result = await checkFBTrades(selectedChain,'number', selectedBlocks, [], [])
        console.log(result)
        if(result){
            setAutoSellTx(result.autoSell)
            setMonitorOnlyTx(result.monitorOnly)
        }
        getChainStatus()
    }
    const rangeInputChange = (event: any, blockType: string) => {
        if(selectedChain==='BTC' && Number(event.target.value) > Number(currentBlockHeightBTC)){
            setError(true)
            setErrorMsg('Block number cannot be larger than the current block height!')
        }
        else if(selectedChain==='ETH' && Number(event.target.value) > Number(currentBlockHeightETH)){
            setError(true)
            setErrorMsg('Block number cannot be larger than the current block height!')
        }
        if(blockType === 'start') {
            console.log(event.target.value)
            setStartBlock(event.target.value)
        }
        else {
            setEndBlock(event.target.value)
        }
    }
    const onBlockRangeSubmit = async() => {
        setCurrentStatus("Checking")
        console.log([startBlock, endBlock])
        let result: transactionResult;
        result = await checkFBTrades(selectedChain,'number', selectedBlocks, [], [])
        console.log(result)
        if(result){
            setAutoSellTx(result.autoSell)
            setMonitorOnlyTx(result.monitorOnly)
        }
        getChainStatus()
    }
    return (
        <div style={{flexDirection: 'column'}}>
            <div style={{marginBottom: 10}}>
                <div style={{display: 'inline-flex', flexDirection: 'row', marginRight: '10px'}}>
                    <div style={{marginRight: '12px', fontWeight: 'bold', margin: 'auto'}}>Last BTC Block Checked: </div>
                    <div style={{marginRight: '10px', margin: 'auto'}}>{lastBlockCheckedBTC}</div>
                </div>
                <div className='blockDetail'>
                    <div style={{marginRight: '12px', fontWeight: 'bold', margin: 'auto'}}>Current BTC Block: </div>
                    <div style={{marginRight: '10px', margin: 'auto'}}>{currentBlockHeightETH}</div>
                </div>
                {/* <div style={{ width: '30%'}}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Check Blocks By</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={checkMethod}
                            label="Check Blocks By"
                            onChange={handleChangeBlockCheckMethod}
                        >
                            <MenuItem value={'number'}>Block Numbers</MenuItem>
                            <MenuItem value={'range'}>Block Range</MenuItem>
                        </Select>
                    </FormControl>
                </div> */}
            </div>
            <div style={{marginBottom: 10}}>
                <div style={{display: 'inline-flex', flexDirection: 'row', marginRight: '10px'}}>
                    <div style={{marginRight: '12px', fontWeight: 'bold', margin: 'auto'}}>Last ETH Block Checked: </div>
                    <div style={{marginRight: '10px', margin: 'auto'}}>{lastBlockCheckedETH}</div>
                </div>
                <div className='blockDetail'>
                    <div style={{marginRight: '12px', fontWeight: 'bold', margin: 'auto'}}>Current ETH Block: </div>
                    <div style={{marginRight: '10px', margin: 'auto'}}>{currentBlockHeightBTC}</div>
                </div>
            </div>
            <div style={{marginBottom: 10}}>
                <div style={{display: 'inline-flex', flexDirection: 'row', marginRight: '10px'}}>
                    <div style={{marginRight: '12px', fontWeight: 'bold', margin: 'auto'}}>Select Chain: </div>
                    <RadioGroup
                        row
                        value={selectedChain}
                        onChange={handleSelectChainChange}
                    >
                        <FormControlLabel value="BTC" control={<Radio />} label="BTC" />
                        <FormControlLabel value="ETH" control={<Radio />} label="ETH" />
                    </RadioGroup>
                    
                </div>
                <FormControl sx={{width: '15%'}}>
                    <InputLabel>Check Blocks By</InputLabel>
                    <Select
                        value={checkMethod}
                        label="Check Blocks By"
                        onChange={handleChangeBlockCheckMethod}
                    >
                        <MenuItem value={'number'}>Block Numbers</MenuItem>
                        <MenuItem value={'range'}>Block Range</MenuItem>
                    </Select>
                </FormControl>
            </div>
            {checkMethod === 'number' ? 
                <div style={{display: 'flex', flexDirection: 'row', alignContent: 'flex-start', marginBottom: 10}}>
                    <div style={{fontWeight: 'bold', margin: 'auto', marginLeft: 0, marginRight:12, width: 'max-content'}}>Selected blocks: </div>
                    <div style={{marginRight: 5}}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Selected Blocks"
                            type={"number"}
                            // multiline
                            value={inputBlock}
                            onChange={inputChange}
                            onKeyDown={handleSelectBlockKeyDown}
                            // multiline
                            InputProps={{
                                startAdornment: 
                                    selectedBlocks.map((item: string) => (
                                        <Chip
                                            key={item}
                                            label={item}
                                            sx={{marginRight: 1}}
                                            onDelete={() => handleDeleteBlockInput(item)}
                                        />
                                    ))
                            }}
                        />
                    </div>
                    <Button variant="contained" color="success" disabled={currentStatus == 'Checking' || selectedBlocks.length === 0} onClick={onBlockNumbersSubmit}>Check</Button>
                </div>
            : null}
            {checkMethod === 'range' ? 
            <div style={{display: 'flex', flexDirection: 'row', alignContent: 'flex-start', marginBottom: 10}}>
                <div style={{fontWeight: 'bold', margin: 'auto', marginLeft: 0, marginRight:12, width: 'max-content'}}>Start block: </div>
                <div>
                    <TextField label="start block" id="outlined-size-small" value={startBlock} size="small"
                        onChange={e => rangeInputChange(e, 'start')}
                    />
                </div>
                <div style={{fontWeight: 'bold', margin: 'auto', marginLeft: 12, marginRight:12, width: 'max-content'}}>End block: </div>
                <div style={{marginRight: 5}}>
                    <TextField label="end block" id="outlined-size-small" placeholder={selectedChain === 'BTC' ? currentBlockHeightBTC : currentBlockHeightETH} value={endBlock} size="small"
                        onChange={e => rangeInputChange(e, 'end')}
                    />
                </div>
                <Button variant="contained" color="success" 
                    disabled={currentStatus == 'Checking' || !startBlock || Number(startBlock) > Number(endBlock) 
                        || (selectedChain === 'BTC' && Number(endBlock) > Number(currentBlockHeightBTC)) 
                        || (selectedChain === 'ETH' && Number(endBlock) > Number(currentBlockHeightETH)) 
                        || (selectedChain === 'BTC' && Number(startBlock) > Number(currentBlockHeightBTC))
                        || (selectedChain === 'ETH' && Number(startBlock) > Number(currentBlockHeightETH)) 
                    }
                    onClick={onBlockRangeSubmit}
                >Check</Button>
            </div> 
            : null}
            {currentStatus == 'Online'?
            <>
                <div className='serviceButton'>
                    <IconButton aria-label="Running" onClick={() => changeChainStatus('Offline')}>
                        <PauseCircleOutlineIcon sx={{fontSize: '200px'}}/>
                    </IconButton>
                        
                </div>
                <div className='serviceButton'>
                    <Chip color="success" label='Running' variant='outlined' sx={{width: '150px', height: '40px', fontWeight: 'bold', fontSize: '20px'}}/>
                </div>
            </>
            :currentStatus == 'Offline'? 
            <>
                <div className='serviceButton'>
                    <IconButton aria-label="onHalt" onClick={() => changeChainStatus('Online')}>
                        <PlayCircleOutlineIcon sx={{fontSize: '200px'}}/>
                    </IconButton>
                        
                </div>
                <div className='serviceButton'>
                    <Chip color="error" label='On Halt' variant='outlined' sx={{width: '150px', height: '40px', fontWeight: 'bold', fontSize: '20px'}}/>
                </div>
            </> 
            :currentStatus == 'Checking'? 
            <>
                <div className='serviceButton' style={{marginTop: 10, marginBottom: 10}}>
                    <ReactLoading type={'spokes'} color={'grey'} height={200} width={200} />
                </div>
                <div className='serviceButton'>
                    <Chip label='Checking Blocks' sx={{width: '150px', height: '40px', fontWeight: 'bold', fontSize: '15px'}}/>
                </div>
            </>
            :<>
                <div className='serviceButton'>
                    <IconButton aria-label="networkError" onClick={() => getChainStatus()}>
                        <ErrorOutlineIcon sx={{fontSize: '200px'}}/>
                    </IconButton>
                        
                </div>
                <div className='serviceButton'>
                    <Chip color="warning" label='Network Error' sx={{width: '150px', height: '40px', fontWeight: 'bold', fontSize: '15px'}}/>
                </div>
            </>}
            <div className='transactionButton'>
                {autoSellTx.length > 0 ? <Button variant="outlined" onClick={() => setOpenAutosellTx(true)}>View Auto Sell Deposit Transactions For {selectedChain}</Button> : null}
                {monitorOnlyTx.length > 0 ? <Button variant="outlined" sx={{marginTop: 2}} onClick={() => setOpenMonitorOnlyTx(true)}>View Monitor Only Deposit Transactions {selectedChain}</Button> :null}
            </div>
            <TransactionTableDialog data={autoSellTx} open={openAutosellTx} handleClose={() => setOpenAutosellTx(false)} tableName={"Auto Sell Deposits"} chain={selectedChain} txType={"autosell"} setTradeLoading={setTradeLoading} tradeLoading={tradeLoading} handleTradeUpdate={handleTradeUpdate}/>
            <TransactionTableDialog data={monitorOnlyTx} open={openMonitorOnlyTx} handleClose={() => setOpenMonitorOnlyTx(false)} tableName={"Monitor Only Deposits"} chain={selectedChain} txType={"monitor"}/>
            <Snackbar open={error} autoHideDuration={6000} onClose={() => setError(false)}>
                <Alert severity="error">{errorMsg}</Alert>
            </Snackbar>
            <Snackbar open={warning} autoHideDuration={6000} onClose={() => setError(false)}>
                <Alert severity="warning">{warningMsg}</Alert>
            </Snackbar>
        </div>
    );
}

export default Fireblocks;