import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AutoSellWarningDialog({open, closeDialog, confirmDialog} : any) {
    const handleCancelAutoSell = () => {
        closeDialog();
    };

    const handleConfirmAutoSell = () => {
        confirmDialog();
    };

    return (
        <Dialog
            open={open}
            onClose={handleCancelAutoSell}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to turn on the autosell?"}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Select cancel or confirm, this needs to be changed later for production i guess.
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleCancelAutoSell}>Cancel</Button>
            <Button onClick={handleConfirmAutoSell} autoFocus>
                Confirm
            </Button>
            </DialogActions>
        </Dialog>
    );
}
