import React, {useState} from 'react';
import Layout from './components/Layout';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from './components/TabPanel';
import BTCChain from './contents/BTCChain';
import EthChain from './contents/EthChain';
import Fireblocks from './contents/Fireblocks';
function Blockchain(){
    const [currentTab, setCurrentTab] = useState(0);

    const handleTabChange = (event: any, newValue: any) => {
        setCurrentTab(newValue);
    };
    return (
        <Layout>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={currentTab} onChange={handleTabChange}>
                        <Tab label="External Ethereum" />
                        <Tab label="External BTC" />
                        <Tab label="Fireblocks" />
                    </Tabs>
                    <TabPanel value={currentTab} index={0}>
                        <EthChain />
                    </TabPanel>
                    <TabPanel value={currentTab} index={1}>
                        <BTCChain />
                    </TabPanel>
                    <TabPanel value={currentTab} index={2}>
                        <Fireblocks />
                    </TabPanel>
                </Box>
            </Box>
        </Layout>
    );
}

export default Blockchain;