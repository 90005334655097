import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import { useAuth0 } from "@auth0/auth0-react";
import LogoutIcon from '@mui/icons-material/Logout';
import Button from '@mui/material/Button';
import './PermissionErrorPage.css'
function PermissionErrorPage() {
    const { logout } = useAuth0();
    const logoutAuth0 = () => {
        console.log("log out")
        logout({ returnTo: window.location.origin })
    }
    return (
        <div style={{height: '100vh', display: 'flex', margin: 'auto', justifyContent: 'center', alignItems: 'center', }}>
            <Card sx={{ width: '30%', borderRadius: '2px', alignSelf: 'center' }}>
                <CardContent sx={{display: 'flex', justifyContent: 'center', flexDirection:'column' }}>
                    <ReportGmailerrorredIcon color='error' sx={{fontSize: 40, alignSelf: 'center'}}/>
                    <span className='error-text'>You don't have access to AXE Wallet</span>
                    <Button variant="text" startIcon={<LogoutIcon />} sx={{color: 'black' }} onClick={() => logoutAuth0()}>
                        Sign Out
                    </Button>
                </CardContent>
            </Card>
        </div>
    );
}

export default PermissionErrorPage;