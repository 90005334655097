import CryptoJS from 'crypto-js';

const SECRET:any = process.env.REACT_APP_AXE_API_SECRET || ""

const generateSignature = (method: any, timeStamp: any, path: any, query: any, body: any) => {

    let params = [method, timeStamp, path] 
    if(query) {
        params.push(query)
    }
    if(body) {
        params.push(JSON.stringify(body))
    }

    let stringParams = params.join('\n');

    const hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, SECRET)

    hmac.update(stringParams)

    return CryptoJS.enc.Base64.stringify(hmac.finalize())
}

export default generateSignature

