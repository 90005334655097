import React, {useEffect, useState} from 'react';
import Layout from './components/Layout';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from './components/TabPanel';
import FireblocksaAutosell from './audit/FireblocksAutosell'
import FireblocksMonitor from './audit/FireblocksMonitor';
import ExternalAutosell from './audit/ExternalAutosell';
import ExternalMonitor from './audit/ExternalMonitor';
import serverApi from '../api/server'
function Audit(){
    const [currentTab, setCurrentTab] = useState(0);
    const [fbAuto, setFbAuto] = useState([])
    const [fbMonitor, setFbMonitor] = useState([])
    const [exAuto, setExAuto] = useState([])
    const [exMonitor, setExMonitor] = useState([])
    useEffect(() => {
        getAuditData()
    },[])
    const handleTabChange = (event: any, newValue: any) => {
        setCurrentTab(newValue);
    };
    const getAuditData = async() : Promise<any[]> => {
        try {
            // create sig

            // const timeStamp = moment().seconds(0).milliseconds(0).toISOString().slice(0, -1) + "000Z"
            // const path = '/api/getB2C2Trades' 

            // const signature = generateSignature("GET", timeStamp, path, null, null) 
            // console.log("get b2c2 trades sig", signature)

            const result = await serverApi.get('/getAudit')
            console.log(result)
            let resultJSONData = result.data
            console.log(resultJSONData)
            setFbAuto(resultJSONData[0])
            setFbMonitor(resultJSONData[1])
            setExAuto(resultJSONData[2])
            setExMonitor(resultJSONData[3])
            return resultJSONData
        }
        catch (err) {
            alert(err)
            return []
        }
    }
    return (
        <Layout>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={currentTab} onChange={handleTabChange}>
                        <Tab label="Fireblocks Autosell" />
                        <Tab label="Fireblocks MonitorOnly" />
                        <Tab label="External Autosell" />
                        <Tab label="External MonitorOnly" />
                    </Tabs>
                    <TabPanel value={currentTab} index={0}>
                        <FireblocksaAutosell data={fbAuto} />
                    </TabPanel>
                    <TabPanel value={currentTab} index={1}>
                        <FireblocksMonitor data={fbMonitor}/>
                    </TabPanel>
                    <TabPanel value={currentTab} index={2}>
                        <ExternalAutosell data={exAuto}/>
                    </TabPanel>
                    <TabPanel value={currentTab} index={3}>
                        <ExternalMonitor data={exMonitor}/>
                    </TabPanel>
                </Box>
            </Box>
        </Layout>
    );
}

export default Audit;