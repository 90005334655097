import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import './EditWalletModal.css'
import { Typography } from 'antd';
import { validate } from 'bitcoin-address-validation';
import Web3 from 'web3'
import AutoSellWarningDialog from './AutoSellWarningDialog';
const {Text} = Typography;
interface Asset {
    AssetPair: string;
    AssetWallet: string;
    Fee: string;
    Mode: string;
    Threshold?:string;
    Notes: string;
    WalletAddr: string;
    OrderType: string;
    LimitPrice: string;
    NotificationEmail: string;
}
const assetPairs = [
    {
        value: 'BTCUSD',
        label: 'BTC-USD'
    },
    {
        value: 'BTCCAD',
        label: 'BTC-CAD'
    },
    {
        value: 'BTCEUR',
        label: 'BTC-EUR'
    },
    {
        value: 'ETHUSD',
        label: 'ETH-USD'
    },
    {
        value: 'ETHCAD',
        label: 'ETH-CAD'
    },
    {
        value: 'ETHEUR',
        label: 'ETH-EUR'
    },
    {
        value: 'USDTUSD',
        label: 'USDT-USD'
    },
    {
        value: 'USDTCAD',
        label: 'USDT-CAD'
    },
    {
        value: 'USDTEUR',
        label: 'USDT-EUR'
    },
    {
        value: 'USDCUSD',
        label: 'USDC-USD'
    },
    {
        value: 'USDCCAD',
        label: 'USDC-CAD'
    },
    {
        value: 'USDCEUR',
        label: 'USDC-EUR'
    },
    {
        value: 'DAIUSD',
        label: 'DAI-USD'
    },
    {
        value: 'DAICAD',
        label: 'DAI-CAD'
    },
    {
        value: 'DAIEUR',
        label: 'DAI-EUR'
    },
    // {
    //     value: 'WETHUSD',
    //     label: 'WETH-USD'
    // },
    // {
    //     value: 'WETHCAD',
    //     label: 'WETH-CAD'
    // },
    // {
    //     value: 'WETHEUR',
    //     label: 'WETH-EUR'
    // },
    {
        value: 'WBTCUSD',
        label: 'WBTC-USD'
    },
    {
        value: 'WBTCCAD',
        label: 'WBTC-CAD'
    },
    {
        value: 'WBTCEUR',
        label: 'WBTC-EUR'
    },
]
export default function EditWalletModal({openDialog, closeDialog, row, asset, onHandleWalletDetailChange, onCancelWalletDetailChange} : any) {
    const [curAsset, setCurAsset] = React.useState<Asset>();
    const [openAutosellWarning, setOpenAutosellWarning] =  React.useState(false);
    React.useEffect(() => {
        setCurAsset(asset)
        if(!openDialog) return function cleanup() {
            console.log('clean up modal')
            setCurAsset(undefined)
        };
    }, [asset, openDialog])
    const handleChange = (type: any, event: any) => {
        console.log("edit wallet modal handle change type: ", type)
        let updateAsset = Object.assign({}, curAsset)
        if(type === 'assetpair') {
            updateAsset.AssetPair = event.target.value
            setCurAsset(updateAsset)
        }
        else if(type === 'mode') {
            updateAsset.Mode = event.target.value
            if(updateAsset.Mode === 'Autosell'){
                setOpenAutosellWarning(true)
            } else {
                setCurAsset(updateAsset)
            }
        }
        else if(type === 'fee') {
            updateAsset.Fee = event.target.value
            setCurAsset(updateAsset)
        }
        else if(type === 'notes') {
            updateAsset.Notes = event.target.value
            setCurAsset(updateAsset)
        }
        else if(type === 'limitprice') {
            updateAsset.LimitPrice = event.target.value
            setCurAsset(updateAsset)
        }
        else if(type === 'ordertype') {
            updateAsset.OrderType = event.target.value
            setCurAsset(updateAsset)
        }
        else if (type === 'notificationemail') {
            updateAsset.NotificationEmail = event.target.value
            setCurAsset(updateAsset)
        }
        else if (type === 'threshold') {
            console.log(event.target.value)
            updateAsset.Threshold = event.target.value
            setCurAsset(updateAsset)
        }
    }
    // console.log(curAsset);
    const isValidEthAddress = (adr: any) => {
        try {
            const web3 = new Web3()
            web3.utils.toChecksumAddress(adr)
            return true
        } catch (e) {
            return false
        }
    }
    
    const getWalletType = (address: string) => {
        let isBTC = validate(address)
        let isETH = isValidEthAddress(address)
        if(isBTC) {
            return 'BTC'
        } 
        else if(isETH) {
            return 'ETH'
        }
    }
    const cancelChangingWalletMode = () => {
        setOpenAutosellWarning(false)
    }
    const confirmChangingWalletMode = () => {
        let updateAsset = Object.assign({}, curAsset)
        updateAsset.Mode = 'Autosell'
        setCurAsset(updateAsset)
        setOpenAutosellWarning(false)
    }
    if(!curAsset) {
        return null
    }
    return (
    <div>
        <Dialog open={openDialog} onClose={() => onCancelWalletDetailChange(row, asset)} fullWidth>
            <DialogTitle>Edit Wallet Details</DialogTitle>
            <DialogContent>
                <div className='dialogRow'>
                    <Text className='label' strong>Asset Pair: </Text>
                    <TextField
                        select
                        value={curAsset.AssetPair}
                        onChange={(e) => handleChange('assetpair', e)}
                        variant="standard"
                    >
                        {getWalletType(curAsset.WalletAddr) === 'BTC' ?
                            assetPairs.filter(pair => pair.label.includes('BTC')).map(option => 
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>)
                            :assetPairs.filter(pair => curAsset.AssetWallet.includes('_T') ? 
                                                            pair.label.split("-")[0]  == curAsset.AssetWallet.split("_")[0] 
                                                            : pair.label.split("-")[0]  == curAsset.AssetWallet).map(option => 
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>)}
                    </TextField>
                </div>
                <div className='dialogRow'>
                    <Text className='label' strong>Wallet Mode: </Text>
                    <TextField
                        select
                        value={curAsset.Mode}
                        onChange={(e) => handleChange('mode', e)}
                        variant="standard"
                    >
                        <MenuItem value={'Autosell'}>
                            Auto sell
                        </MenuItem>
                        <MenuItem value={'MonitorOnly'}>
                            Monitor Only
                        </MenuItem>
                    </TextField>
                </div>
                {curAsset.Mode === 'Autosell' ?
                <>
                    <div className='dialogRow'>
                        <Text className='label' strong>Order Type: </Text>
                        <TextField
                            select
                            value={curAsset.OrderType}
                            onChange={(e) => handleChange('ordertype', e)}
                            variant="standard"
                            disabled
                        >
                            <MenuItem value={'Market'}>
                                Market
                            </MenuItem>
                            <MenuItem value={'Limit'}>
                                Limit
                            </MenuItem>
                        </TextField>
                    </div>
                    <div className='dialogRow'>
                        <Text className='label' strong>Threshold: </Text>
                        <TextField 
                            id="standard-basic" 
                            variant="standard" 
                            type="number" 
                            value={curAsset.Threshold} 
                            onChange={(e) => handleChange('threshold', e)}/>
                    </div>
                    {curAsset.OrderType === 'Limit' ?
                    <div className='dialogRow'>
                        <Text className='label' strong>Limit Price: </Text>
                        <TextField 
                            id="standard-basic" 
                            variant="standard" 
                            type="number" 
                            value={curAsset.LimitPrice} 
                            onChange={(e) => handleChange('limitprice', e)}/>
                    </div> : null}
                </> : null}
                <div className='dialogRow'>
                    <Text className='label' strong>Wallet Fee (in decimal form): </Text>
                    <TextField 
                        id="standard-basic" 
                        variant="standard" 
                        type="number" 
                        value={curAsset.Fee} 
                        onChange={(e) => handleChange('fee', e)}/>
                </div>
                <div className='dialogRow'>
                    <TextField
                        id="outlined-multiline-static"
                        label="Notes"
                        sx={{width: '100%'}}
                        multiline
                        rows={4}
                        defaultValue={curAsset.Notes && curAsset.Notes !== "\"\"" ? curAsset.Notes : ""}
                        onChange={(e) => handleChange('notes', e)}
                    />
                </div>
                <div className='dialogRow'>
                    <TextField
                        id="outlined-multiline-static"
                        label="Notification Email"
                        sx={{width: '100%'}}
                        defaultValue={curAsset.NotificationEmail ? curAsset.NotificationEmail : ""}
                        onChange={(e) => handleChange('notificationemail', e)}
                    />
                </div>
            </DialogContent>
            <DialogActions sx={{marginRight: '15px'}}>
                <Button onClick={() => onCancelWalletDetailChange(row, asset)} color="error">Cancel</Button>
                <Button onClick={() => onHandleWalletDetailChange(row, asset, curAsset)} variant="contained" color="success">Save</Button>
            </DialogActions>
        </Dialog>
        <AutoSellWarningDialog 
            open={openAutosellWarning}
            closeDialog={cancelChangingWalletMode}
            confirmDialog={confirmChangingWalletMode}
        />
    </div>
    );
  }
  