import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import './EditWalletModal.css'
import { Typography } from 'antd';
import serverApi from '../../api/server';
import generateSignature from '../../api/apiAuth';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import moment from 'moment';

// var WAValidator = require('wallet-address-validator');
const {Text} = Typography;

export default function AddClientVaultModal({mode, openDialog, closeDialog, row, updateWalletList, setUpdateWalletList} : any) {
    const [clientName, setClientName] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    // React.useEffect(() => {
    //     let updateAsset = Object.assign({}, curAsset)
    //     if(mode === 'sdm') {
    //         updateAsset.LiqProvider = 'DVCHAIN'
    //     } else {
    //         updateAsset.LiqProvider = 'B2C2'
    //     }
    //     setCurAsset(updateAsset)
    // }, [mode])
    const handleClientNameChange = (event: any) => {
        console.log(event.target.value)
        setClientName(event.target.value)
    }
    const onCreateNewClient = async() => {
        setLoading(true)

        // create sig

        const timeStamp = moment().seconds(0).milliseconds(0).toISOString().slice(0, -1) + "000Z"
        const path = '/api/updateClient' 

        const data = {
            clientName: 'AXE ' + clientName,
            unit: mode
        }

        const signature = generateSignature("POST", timeStamp, path, mode, data) 

        try {
            
            const result = await serverApi.post('/createNewFBClient', data, {headers: {Authorization: signature}})
            // console.log(result)
            console.log(result)
            if(result.status === 200) {
                let counter = updateWalletList + 1;
                setTimeout(()=> {
                    setClientName('')
                    setUpdateWalletList(counter)
                    closeDialog()
                    setLoading(false)
                }, 5000)
                
            }
            else {
                setLoading(false)
                alert(result.data)
            }
            
        } catch (err) {
            setLoading(false)
            console.log(err)
            alert(err)
        }
    }
    return (
    <div>
        <Dialog open={openDialog} onClose={() => closeDialog()} fullWidth>
            <DialogTitle>Add New Client Vault</DialogTitle>
            <DialogContent>
                <div className='dialogRow'>
                    <Text className='label' strong>Client Name: </Text>
                    <TextField
                        id="outlined-multiline-static"
                        // label="Wallet Address"
                        variant="standard" 
                        sx={{width: '60%'}}
                        value={clientName}
                        onChange={(e) => handleClientNameChange(e)}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">AXE</InputAdornment>,
                        }}
                    />
                </div>
            </DialogContent>
            <DialogActions sx={{marginRight: '15px'}}>
                <Button onClick={() => closeDialog()} color="error">Cancel</Button>
                {loading? 
                <LoadingButton
                    loading
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="outlined"
                >
                    Create
                </LoadingButton>
                :<Button onClick={() => onCreateNewClient()} variant="contained" color="success" 
                disabled={!clientName}>Create</Button>}
            </DialogActions>
        </Dialog>
    </div>
    );
}

