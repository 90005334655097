import React, {useEffect, useState} from 'react';
import Layout from './Layout';
import { Typography } from 'antd';
// import Box from '@mui/material/Box';
// import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
// import TabPanel from './TabPanel';
// import EditWalletModal from './EditWalletModal';
// import TransactionInfoModal from './TransactionInfoModal';
import Tooltip from '@mui/material/Tooltip';
import EditExternalWalletModal from './EditExternalWalletModal';
import moment from 'moment';
// import InfoIcon from '@mui/icons-material/Info';
// import TextField from '@mui/material/TextField';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CloseIcon from '@mui/icons-material/Close';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import LinearProgress from '@mui/material/LinearProgress';
import AddIcon from '@mui/icons-material/Add';
import AddWalletModal from './AddWalletModal'
import { useMode } from 'src/hooks/ModeContext';
import copy from 'copy-to-clipboard';
import DeleteIcon from '@mui/icons-material/Delete';
import Snackbar from '@mui/material/Snackbar';
import DeleteWalletWarning from './DeleteWalletWarning'
// fetch api
import serverApi from '../../api/server';
import generateSignature from '../../api/apiAuth'
// import { Table, Badge, Menu, Dropdown, Button } from 'antd';
// import {DownOutlined} from '@ant-design/icons';
// import './Page.css'
// mock data
// import {clientsMock, transactionData as transactions } from '../mockData/overviewMock'

const { Title, Text } = Typography;
interface ClientWallet {
    AcctId: string;
    Assets?: {
        AssetPair: string;
        AssetWallet: string;
        Fee: string;
        Mode: string;
        Notes: string;
        WalletAddr: string;
    }[];
    DefaultFee: string;
    LiqProvider: string;
    Name: string;
}
interface Asset {
    AssetPair: string;
    AssetWallet: string;
    Fee: string;
    Mode: string;
    Notes: string;
    WalletAddr: string;
    LiqProvider: string;
}
const provider:any = {
    'B2C2': 'B2C2',
    'DVCHAIN': 'DVChain'
}
function Row({mode, asset,handleOpenEditWalletDialog, selected, updateWalletList, setUpdateWalletList, openDeleteWalletWarnning} : any) {
    const copyToClipboard = (walletAddress: string) => {
        copy(walletAddress)
    }
    
    return (
        <>
            <TableRow selected={selected}>
                <TableCell component="th" scope="row">
                        {asset.AssetPair}
                </TableCell>
                <TableCell>{asset.AssetWallet}</TableCell>
                <Tooltip title={asset.WalletAddr} placement="top"><TableCell>
                    <Button variant="text" onClick={() => copyToClipboard(asset.WalletAddr)}>{asset.WalletAddr.substr(0, 7) + '...' + asset.WalletAddr.substr(asset.WalletAddr.length-7, asset.WalletAddr.length)}</Button>
                </TableCell></Tooltip>
                <Tooltip title={asset.Description} placement="top"><TableCell><span style={{ whiteSpace: 'nowrap', overflow: 'hidden', width: '150px', display: 'block' }}>{asset.Description}</span></TableCell></Tooltip>
                <Tooltip title={asset.Notes && asset.Notes !== "\"\""? asset.Notes : ''} placement="top"><TableCell><span style={{ whiteSpace: 'nowrap', overflow: 'hidden', width: '150px', display: 'block' }}>{asset.Notes && asset.Notes !== "\"\""? asset.Notes : null}</span></TableCell></Tooltip>
                <TableCell>{asset.Mode}</TableCell>
                <TableCell>{asset.Threshold? asset.Threshold: 0}</TableCell>
                <TableCell>{asset.Fee}</TableCell>
                <TableCell>{asset.LiqProvider}</TableCell>
                <TableCell>
                    <Button variant="text" onClick={() => handleOpenEditWalletDialog(asset)}>Edit</Button>
                </TableCell>
                <TableCell>
                    <IconButton onClick={() => openDeleteWalletWarnning(asset)}>
                        <DeleteIcon />
                    </IconButton>
                </TableCell>
                </TableRow>
        </>
    );
}

function ExternalWalletTable() {
    //const filters = ['Destination', 'Asset', 'Source', 'Date']
    const [clientWalletData, setClientWalletData] = useState<Asset[]>([]);
    // const [transactionData, setTransactionData] = useState<any[]>([]);
    const [currentTab, setCurrentTab] = useState(0);
    const [openEditWallet, setOpenEditWallet] = useState(false);
    const [currentSelectedVault, setCurrentSelectedVault] = useState({});
    const [currentSelectedWallet, setCurrentSelectedWallet] = useState<Asset>();
    // const [openTxInfo, setOpenTxInfo] = useState(false);
    // const [txData, setTxData] = useState();
    // const [curTxIndex, setCurTxIndex] = useState();
    // const [openFilter, setOpenFilter] = useState(false);
    // const [filterBy, setFilterBy] = useState();
    const [filterVault, setFilterVault] = useState('');
    const [filterAsset, setFilterAsset] = useState('');
    const [filterSource, setFilterSource] = useState('');
    const [filterDateStart, setFilterDateStart] = useState(null);
    const [filterDateEnd, setFilterDateEnd] = useState(null);
    const [loading, setLoading] = useState(false);
    // search client 
    const [searchValue, setSearchValue] = useState('');
    const [searchResult, setSearchResult] = useState<Asset[]>([]);
    // search fireblock tx
    const [searchTXValue, setSearchTXValue] = useState('');
    const [searchTXResult, setSearchTXResult] = useState<any[]>([]);
    const [openAddWallet, setOpenAddWallet] = useState(false);
    const [updateWalletList, setUpdateWalletList] = useState(0);
    const [state, action] = useMode()
    const [openAlert, setOpenAlert] = useState(false)
    const [openDeleteWarning ,setOpenDeleteWarning] = useState(false)
    const handleCloseAlert = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
    
        setOpenAlert(false);
    };
    // const [hasFilter, setHasFilter] = useState(false);
    const handleTabChange = (event: any, newValue: any) => {
        setCurrentTab(newValue);
    };
    const getClientWalletData = async(): Promise<any[]> => {
        // generate signature

        const timeStamp = moment().seconds(0).milliseconds(0).toISOString().slice(0, -1) + "000Z"
        const query = state.mode.toUpperCase()
        const path = `/api/returnExternalAccounts?unit=${query}` 

        const signature = generateSignature("GET", timeStamp, path, query, null) 

        try {
            const result = await serverApi.get('/returnExternalAccounts?unit=' + state.mode.toUpperCase(), {headers: {Authorization: signature}})
            // const txNew = await serverApi.get('/getExtTransactions')
            // console.log(txNew)
            // console.log(result)
            let resultJSONData = result.data
            console.log(resultJSONData)
            // setClientWalletData(resultJSONData)
            return resultJSONData
            // if(resultJSONData.Assets) {
            //     return resultJSONData.Assets
            // }
            // else {
            //     return []
            // }
        }
        catch (err) {
            alert(err)
            return []
        }
    }
    useEffect(() => {
        setClientWalletData([]);
        setLoading(true)
        getClientWalletData().then(res => {
            console.log(res)
            setClientWalletData(res);
            setLoading(false)
        });
    }, [updateWalletList, state.mode])
    // useEffect(() => {
    //     setTransactionData(transactions)
    // },[transactions])
    const updateLiquidityProvider = async(rowIdx: string, provider: string) => {
        try {
            const jsonData = {
                clientIndex: rowIdx,
                provider: provider
            };

            const timeStamp = moment().seconds(0).milliseconds(0).toISOString().slice(0, -1) + "000Z"
            const path = 'api/updateClient' 

            const signature = generateSignature("POST", timeStamp, path, null, jsonData) 

            const res = await serverApi.post(`/updateClient`, jsonData, {headers: {Authorization: signature}})
            // console.log(res)
        }
        catch (err) {
            alert(err)
        }
    }
    const handleChangeLiquidityProvider = async(event: any, row: any) => {
        let updatedLP = event.target.value
        // console.log(row)
        let updateArr = [...clientWalletData];
        let curentRowIndex = updateArr.findIndex(i => i === row);
        const lpname : string = provider[updatedLP]
        // console.log(lpname)
        await updateLiquidityProvider(curentRowIndex.toString(), lpname)
        updateArr[curentRowIndex].LiqProvider = updatedLP
        setClientWalletData(updateArr)

    }
    const updateWalletDetail = async(row: any, updatedAsset: any) => {
        try {
            const jsonData = {
                pair: updatedAsset.AssetPair,
                asset: updatedAsset.AssetWallet,
                description: updatedAsset.Description,
                notes: updatedAsset.Notes,
                address: row.WalletAddr,
                mode: updatedAsset.Mode,
                fee: updatedAsset.Fee,
                liqprovider: updatedAsset.LiqProvider,
                threshold: updatedAsset.Threshold,
            };
            // create sig

            const timeStamp = moment().seconds(0).milliseconds(0).toISOString().slice(0, -1) + "000Z"
            const path = '/api/updateExternalWallet' 

            const signature = generateSignature("POST", timeStamp, path, null, jsonData) 

            const res = await serverApi.post(`/updateExternalWallet`, jsonData, {headers: {Authorization: signature}})

            console.log(res)
        }
        catch (err) {
            alert(err)
        }
    }
    const handleWalletDetailChange = async(acct: any, asset: any, updatedAsset: any) => {
        // TODO: POST UPDATED INFO TO API HERE 
        let updateArr = [...clientWalletData];
        let curentRowIndex = updateArr.findIndex(i => i === asset);
        let currentRow = updateArr[curentRowIndex]
        if(currentRow) {
            //const clientWalletId = acct.AccId+'_'+ currentRow.WalletAddr
            currentRow = updatedAsset;
            let res = await updateWalletDetail(currentRow, updatedAsset)
            console.log(res)
            setClientWalletData(updateArr)
            setOpenEditWallet(false)
            //setCurrentSelectedVault({})
            setCurrentSelectedWallet(undefined)
        }
    }
    const onCancelWalletDetailChange = (row: any, asset: any) => {
        setOpenEditWallet(false)
        //setCurrentSelectedVault({})
        setCurrentSelectedWallet(undefined)
    }
    const handleOpenEditWalletDialog = (asset: any) => {
        // console.log(row)
        // console.log(asset)
        // setCurrentSelectedVault(row)
        setCurrentSelectedWallet(asset)
        setOpenEditWallet(true)
    }
    // Transaction Info modal
    // const handleOpenTxInfoDialog = (row: any, index: any) => {
    //     setTxData(row)
    //     setCurTxIndex(index)
    //     setOpenTxInfo(true)
    // }
    // const handleCloseTxInfoDialog = () => {
    //     setOpenTxInfo(false)
    //     setCurTxIndex(undefined)
    // }
    // const openFilterMenu = () => {
    //     setOpenFilter(true)
    // }
    // const closeFilterMenu = () => {
    //     setOpenFilter(false)
    // }
    // const filterWithVault = (arr: any[], vault: string) => {
    //     let result = arr.filter(item => item.destination.name === vault);
    //     return result
    // }
    // const handleVaultFilterChange = (event: any) => {
    //     setFilterVault(event.target.value);
    // }
    // const filterWithAsset = (arr: any[], assetName: string) => {
    //     return arr.filter(item => item.assetId === assetName);
    // }
    // const handleAssetFilterChange = (event:any) => {
    //     setFilterAsset(event.target.value)
    // }
    // const filterWithSource = (arr: any[], source: string) => {
    //     return arr.filter(item => item.source.name === source);
    // }
    // const handleSourceFilterChange = (event: any) => {
    //     setFilterSource(event.target.value)
    // }
    // const filterWithDateRange = (arr: any[]) => {
    //     return filterDateStart && filterDateEnd? arr.filter(item => moment(item.lastUpdated).isSameOrAfter(filterDateStart, 'day') && moment(item.lastUpdated).isSameOrBefore(filterDateEnd)) : arr;
    // }
    // const filterWithStartDate = (arr:any[]) => {
    //     return filterDateStart? arr.filter(item => new Date(item.lastUpdated) >= new Date(filterDateStart)) : arr;
    // }
    // const filterWithEndDate = (arr: any[]) => {
    //     return filterDateStart ? arr.filter(item => new Date(item.lastUpdated) <= new Date(filterDateStart)) : arr;
    // }
    const clearFilters = () => {
        setFilterVault('');
        setFilterAsset('')
        setFilterSource('')
        setFilterDateStart(null)
        setFilterDateEnd(null)
        // setTransactionData(transactions)
    }
    //search client name
    const handleSearchWalletValueOnChange = (e: any) => {
        let inputValue = (e.target.value).trim()
        setSearchValue(inputValue)
        let result = clientWalletData.filter((i: any) => (i.WalletAddr).toUpperCase().includes(inputValue.toUpperCase()))
        // console.log(result)
        setSearchResult(result)
    }
    const handleClickClearSearchInput = () => {
        setSearchValue('')
    }
    const handleSearchTXclient = (e:any) => {
        let inputValue = (e.target.value).trim()
        setSearchTXValue(inputValue)
        // let 
        // console.log(result)
        // setSearchTXResult(result)
    }
    const handleClickClearTransactionSearchInput = () => {
        setSearchTXValue('')
    }
    const handleAddWalletModal = (state: boolean) => {
        setOpenAddWallet(state)
    }
    const handleOpenDeleteSuccessAlert = () => {
        setOpenAlert(true)
    }
    const openDeleteWalletWarnning = (asset: any) => {
        setCurrentSelectedWallet(asset)
        setOpenDeleteWarning(true)
    }
    const closeDeleteWalletWarnning = () => {
        setCurrentSelectedWallet(undefined)
        setOpenDeleteWarning(false)
    }
    // const deleteFireBlocksWallet = async(acctId: string, assetpair: string, address: string) => {
    //     try{
    //         let counter = updateWalletList;
    //         let jsonData = {
    //             unit: state.mode,
    //             AcctId: curDelWallet.acctId,
    //             assetpair: curDelWallet.assetpair,
    //             address: curDelWallet.address,
    //         }
    //         console.log(jsonData)
    //         const res = await serverApi.post(`/delFBWallet`, jsonData)
    //         console.log(res)
    //         if(res.status === 200){
    //             counter = updateWalletList + 1
    //             setUpdateWalletList(counter)
    //             handleOpenDeleteSuccessAlert()
    //             closeDeleteFireBlocksWalletWarnning()
    //         }
    //     } catch(err) {
    //         console.log(err)
    //     }
    // }
    const deleteWallet = async(assetpair: string, address:string) => {
        try{
            let counter = updateWalletList;
            //console.log(currentSelectedWallet)
            let jsonData = {
                unit: state.mode,
                assetpair: currentSelectedWallet?.AssetPair,
                address: currentSelectedWallet?.WalletAddr,
            }
            // generate sig

            const timeStamp = moment().seconds(0).milliseconds(0).toISOString().slice(0, -1) + "000Z"
            const path = '/api/delExternalWallet' 

            const signature = generateSignature("POST", timeStamp, path, null, jsonData) 

            const res = await serverApi.post('/delExternalWallet', jsonData, {headers: {Authorization: signature}})
            //console.log(jsonData)
            if(res.status === 200){
                counter = updateWalletList + 1
                setUpdateWalletList(counter)
                handleOpenDeleteSuccessAlert()
                closeDeleteWalletWarnning()
            }
        } catch(err) {
            console.log(err)
        }
    }
    return (
        <div className='clientsWalletContainer'>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <Paper
                    component="form"
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 350, marginBottom: 1 }}
                >
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        value={searchValue}
                        onChange={(e) => handleSearchWalletValueOnChange(e)}
                        placeholder="Search Wallet Address"
                        inputProps={{ 'aria-label': 'search wallet address' }}
                        disabled={clientWalletData.length === 0}
                        endAdornment={searchValue? 
                            <InputAdornment position="end">
                                <IconButton
                                    size='small'
                                    onClick={handleClickClearSearchInput}
                                    style={{borderRadius: 0}}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </InputAdornment> : <SearchIcon />}
                    />
                    
                </Paper>
                {clientWalletData.length > 0 ? <Button sx={{height: '90%'}} variant="outlined" size="small" endIcon={<AddIcon />} onClick={() => handleAddWalletModal(true)}>
                    Add Wallet
                </Button> : null}
            </div>
            
            {clientWalletData.length > 0 ?
            <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
                {loading ? <LinearProgress color="success"/> : null}
                <Table stickyHeader aria-label="collapsible table" size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell><Text strong>Asset Pair</Text></TableCell>
                            <TableCell><Text strong>Wallet Type</Text></TableCell>
                            <TableCell><Text strong>Address</Text></TableCell>
                            <TableCell><Text strong>Decription</Text></TableCell>
                            <TableCell><Text strong>Notes</Text></TableCell>
                            <TableCell><Text strong>Mode</Text></TableCell>
                            <TableCell><Text strong>Threshold</Text></TableCell>
                            <TableCell><Text strong>Fee</Text></TableCell>
                            <TableCell><Text strong>Liquidity Provider</Text></TableCell>
                            <TableCell />
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {searchValue? 
                            searchResult.map((client, index) => (
                                <Row 
                                    key={client.WalletAddr + '-' + index} 
                                    mode={state.mode}
                                    asset={client} 
                                    selected={client == currentSelectedWallet}
                                    handleChangeLiquidityProvider={handleChangeLiquidityProvider} 
                                    handleAddWalletModal={handleAddWalletModal}
                                    handleWalletDetailChange={handleWalletDetailChange}
                                    handleOpenEditWalletDialog={handleOpenEditWalletDialog}
                                    updateWalletList={updateWalletList}
                                    setUpdateWalletList={setUpdateWalletList}
                                    openDeleteWalletWarnning={openDeleteWalletWarnning}
                                    // handleOpenAlert={() => setOpenAlert(true)}
                                />
                            ))
                            :clientWalletData.map((client, index) => (
                                <Row 
                                    key={client.WalletAddr + '-' + index} 
                                    mode={state.mode}
                                    asset={client}
                                    selected={client == currentSelectedWallet}
                                    handleChangeLiquidityProvider={handleChangeLiquidityProvider} 
                                    handleAddWalletModal={handleAddWalletModal}
                                    handleWalletDetailChange={handleWalletDetailChange}
                                    handleOpenEditWalletDialog={handleOpenEditWalletDialog}
                                    updateWalletList={updateWalletList}
                                    setUpdateWalletList={setUpdateWalletList}
                                    openDeleteWalletWarnning={openDeleteWalletWarnning}
                                    // handleOpenAlert={() => setOpenAlert(true)}
                                />
                        )) }
                    </TableBody>
                </Table>
            </TableContainer> : 
            <div style={{display: 'flex', flexDirection: 'column', width: '20%'}}>
                <Text strong>There's no external client wallet</Text>
                <Button variant="outlined" size="small" endIcon={<AddIcon />} onClick={() => handleAddWalletModal(true)}>
                    Add Wallet
                </Button>

            </div>
            }
            <AddWalletModal 
                openDialog={openAddWallet} 
                closeDialog={() => setOpenAddWallet(false)} 
                row={currentSelectedVault} 
                updateWalletList={updateWalletList}
                setUpdateWalletList={setUpdateWalletList}
                mode={state.mode}
                // asset={currentSelectedWallet} 
                // onHandleWalletDetailChange={handleWalletDetailChange}
                // onCancelWalletDetailChange={() => setOpenAddWallet(false)}
            />
            <EditExternalWalletModal 
                openDialog={openEditWallet} 
                closeDialog={() => setOpenEditWallet(false)} 
                row={currentSelectedVault} 
                asset={currentSelectedWallet} 
                onHandleWalletDetailChange={handleWalletDetailChange}
                onCancelWalletDetailChange={onCancelWalletDetailChange}
                updateWalletList={updateWalletList}
                setUpdateWalletList={setUpdateWalletList}
            />
            <Snackbar
                open={openAlert}
                autoHideDuration={6000}
                onClose={handleCloseAlert}
                message="Wallet deleted successfully"
                action={(
                    <>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={handleCloseAlert}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </>
                )}
            />
            <DeleteWalletWarning 
                open={openDeleteWarning}
                closeDialog={closeDeleteWalletWarnning}
                confirmDialog={deleteWallet}
            />
        </div>
    );
}

export default ExternalWalletTable;