import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import './EditWalletModal.css'
import { Typography } from 'antd';
import serverApi from '../../api/server';
import generateSignature from '../../api/apiAuth';
import { validate, getAddressInfo } from 'bitcoin-address-validation';
import Web3 from 'web3'
import moment from 'moment';

// var WAValidator = require('wallet-address-validator');
const {Text} = Typography;
const isAxmed = process.env.REACT_APP_AXMED === 'true' ? true:false
interface Asset {
    AssetPair: string;
    AssetWallet: string;
    Fee: string;
    Mode: string;
    Threshold?: string;
    Notes: string;
    Description: string;
    WalletAddr: string;
    LiqProvider: string;
}
const assetPairs = [
    {
        value: 'BTCUSD',
        label: 'BTC-USD'
    },
    {
        value: 'BTCCAD',
        label: 'BTC-CAD'
    },
    {
        value: 'BTCEUR',
        label: 'BTC-EUR'
    },
    {
        value: 'ETHUSD',
        label: 'ETH-USD'
    },
    {
        value: 'ETHCAD',
        label: 'ETH-CAD'
    },
    {
        value: 'ETHEUR',
        label: 'ETH-EUR'
    },
    {
        value: 'USDTUSD',
        label: 'USDT-USD'
    },
    {
        value: 'USDTCAD',
        label: 'USDT-CAD'
    },
    {
        value: 'USDTEUR',
        label: 'USDT-EUR'
    },
    {
        value: 'USDCUSD',
        label: 'USDC-USD'
    },
    {
        value: 'USDCCAD',
        label: 'USDC-CAD'
    },
    {
        value: 'USDCEUR',
        label: 'USDC-EUR'
    },
    {
        value: 'DAIUSD',
        label: 'DAI-USD'
    },
    {
        value: 'DAICAD',
        label: 'DAI-CAD'
    },
    {
        value: 'DAIEUR',
        label: 'DAI-EUR'
    },
    // {
    //     value: 'WETHUSD',
    //     label: 'WETH-USD'
    // },
    // {
    //     value: 'WETHCAD',
    //     label: 'WETH-CAD'
    // },
    // {
    //     value: 'WETHEUR',
    //     label: 'WETH-EUR'
    // },
    {
        value: 'WBTCUSD',
        label: 'WBTC-USD'
    },
    {
        value: 'WBTCCAD',
        label: 'WBTC-CAD'
    },
    {
        value: 'WBTCEUR',
        label: 'WBTC-EUR'
    },
]
const axmedAssetPairs = [
    {
        value: 'BTCUSDT',
        label: 'BTC-USDT'
    },{
        value: 'BTCUSDC',
        label: 'BTC-USDC'
    },
    {
        value: 'ETHUSDT',
        label: 'ETH-USDT'
    },
    {
        value: 'ETHUSDC',
        label: 'ETH-USDC'
    },
    {
        value: 'USDTUSDC',
        label: 'USDTUSDC'
    },
    {
        value: 'USDCUSDT',
        label: 'USDC-USDT'
    },
    {
        value: 'DAIUSDT',
        label: 'DAI-USDT'
    },
    {
        value: 'DAIUSDC',
        label: 'DAI-USDC'
    },
    // {
    //     value: 'WETHUSDT',
    //     label: 'WETH-USDT'
    // },
    // {
    //     value: 'WETHUSDC',
    //     label: 'WETH-USDC'
    // },
    {
        value: 'WBTCUSDT',
        label: 'WBTC-USDT'
    },
    {
        value: 'WBTCUSDC',
        label: 'WBTC-USDC'
    },
]
export default function AddWalletModal({mode, openDialog, closeDialog, row, updateWalletList, setUpdateWalletList} : any) {
    const [curAsset, setCurAsset] = React.useState<Asset>();
    // const [walletInputError, setWalletInputError] = React.useState(false);
    // React.useEffect(() => {
    //     console.log(openDialog)
    //     setCurAsset(asset)
    //     if(!openDialog) return function cleanup() {
    //         console.log('clean up modal')
    //         setCurAsset(undefined)
    //     };
    // }, [asset, openDialog])
    React.useEffect(() => {
        let updateAsset = Object.assign({}, curAsset)
        console.log(mode)
        if(mode === 'sdm') {
            updateAsset.LiqProvider = 'DVCHAIN'
        } else {
            updateAsset.LiqProvider = 'B2C2'
        }
        setCurAsset(updateAsset)
    }, [openDialog])
    const handleChange = (type: any, event: any) => {
        console.log(type + ': ' + event.target.value)
        let updateAsset = Object.assign({}, curAsset)
        if(type === 'assetpair') {
            updateAsset.AssetPair = event.target.value
            console.log('USDT ' + updateAsset.AssetPair.includes('USDT'));
            console.log('USDC ' + updateAsset.AssetPair.includes('USDC'));
            if(isAxmed){
                if(updateAsset.AssetPair.substring(0,4).includes('BTC') && !updateAsset.AssetPair.includes('WBTC')){
                    updateAsset.AssetWallet = 'BTC'
                }
                else if(updateAsset.AssetPair.substring(0,4).includes('ETH') && !updateAsset.AssetPair.includes('WETH')) {
                    updateAsset.AssetWallet = 'ETH'
                }
                else if(updateAsset.AssetPair.substring(0,4).includes('USDT')) {
                    updateAsset.AssetWallet = 'USDT'
                }
                else if(updateAsset.AssetPair.substring(0,4).includes('USDC')) {
                    updateAsset.AssetWallet = 'USDC'
                }
                else if(updateAsset.AssetPair.substring(0,4).includes('WBTC')) {
                    updateAsset.AssetWallet = 'WBTC'
                }
                else if(updateAsset.AssetPair.substring(0,4).includes('WETH')) {
                    updateAsset.AssetWallet = 'WETH'
                }
                else if(updateAsset.AssetPair.substring(0,4).includes('DAI')) {
                    updateAsset.AssetWallet = 'DAI'
                }
            }
            else{
                if(updateAsset.AssetPair.includes('BTC') && !updateAsset.AssetPair.includes('WBTC')){
                    updateAsset.AssetWallet = 'BTC'
                }
                else if(updateAsset.AssetPair.includes('ETH') && !updateAsset.AssetPair.includes('WETH')) {
                    updateAsset.AssetWallet = 'ETH'
                }
                else if(updateAsset.AssetPair.includes('USDT')) {
                    updateAsset.AssetWallet = 'USDT'
                }
                else if(updateAsset.AssetPair.includes('USDC')) {
                    updateAsset.AssetWallet = 'USDC'
                }
                else if(updateAsset.AssetPair.includes('WBTC')) {
                    updateAsset.AssetWallet = 'WBTC'
                }
                else if(updateAsset.AssetPair.includes('WETH')) {
                    updateAsset.AssetWallet = 'WETH'
                }
                else if(updateAsset.AssetPair.includes('DAI')) {
                    updateAsset.AssetWallet = 'DAI'
                }
            }
            
        }
        // else if(type === 'type') {
        //     updateAsset.AssetWallet = event.target.value
        // }
        else if(type === 'mode') {
            updateAsset.Mode = event.target.value
        }
        else if(type === 'address') {
            updateAsset.WalletAddr = event.target.value
        }
        else if(type === 'fee') {
            updateAsset.Fee = event.target.value
        }
        else if(type === 'description') {
            updateAsset.Description = event.target.value
        }
        else if(type === 'notes') {
            updateAsset.Notes = event.target.value
        }
        else if(type === 'liqprovider') {
            updateAsset.LiqProvider = event.target.value
        }
        else if(type === 'threshold') {
            updateAsset.Threshold = event.target.value
        }
        setCurAsset(updateAsset)
    }
    const isValidEthAddress = (adr: any) => {
        try {
            const web3 = new Web3()
            web3.utils.toChecksumAddress(adr)
            return true
        } catch (e) {
            return false
        }
    }
    
    const onHandleSaveNewWallet = async() => {
        console.log(row)
        console.log(curAsset)
        try {
            let walletAddressValid
            if(!curAsset?.Fee && curAsset){
                curAsset.Fee = '0.05'
            }
            // wallet address validation
            if(curAsset?.AssetPair.includes('BTC')&& !curAsset?.AssetPair.includes('WBTC')){
                walletAddressValid = validate(curAsset?.WalletAddr)
            }
            else {
                walletAddressValid = isValidEthAddress(curAsset?.WalletAddr)
            }
            // setWalletInputError(walletAddressValid)
            // console.log(walletAddressValid)
            if(walletAddressValid) {

                // generate sig

                const timeStamp = moment().seconds(0).milliseconds(0).toISOString().slice(0, -1) + "000Z"
                const path = '/api/addExternalWallet' 
    
                const data = {
                    AccountId: row.AcctId,
                    Wallet: curAsset
                }

                const signature = generateSignature("POST", timeStamp, path, null, data) 
    

                const result = await serverApi.post('/addExternalWallet', data, {headers: {Authorization: signature}})
                // console.log(result)
                console.log(result)
                if(result.status === 200 && result.data == 'Wallet added') {
                    let counter = updateWalletList + 1;
                    closeDialog()
                    setCurAsset({} as Asset)
                    setUpdateWalletList(counter)
                }
                else {
                    alert(result.data)
                }
            }
            else {
                alert("Wallet address validation failed")
            }
            
        }
        catch (err) {
            alert(err)
            return
        }
    }
   
    // console.log(curAsset);
    
    // if(!curAsset) {
    //     return null
    // }
    return (
    <div>
        <Dialog open={openDialog} onClose={() => closeDialog()} fullWidth>
            <DialogTitle>New Wallet Details</DialogTitle>
            <DialogContent>
                <div className='dialogRow'>
                    <Text className='label' strong>Liquidity Provider: </Text>
                    <TextField
                        select
                        disabled
                        value={curAsset?.LiqProvider ? curAsset?.LiqProvider : ''}
                        onChange={(e) => handleChange('liqprovider', e)}
                        variant="standard"
                    >
                        <MenuItem value={'DVCHAIN'}>DV Chain</MenuItem>
                        <MenuItem value={'B2C2'}>B2C2</MenuItem>
                    </TextField>
                </div>
                <div className='dialogRow'>
                    <Text className='label' strong>Asset Pair: </Text>
                    <TextField
                        select
                        value={curAsset?.AssetPair ? curAsset?.AssetPair : ''}
                        onChange={(e) => handleChange('assetpair', e)}
                        variant="standard"
                    >
                        {(isAxmed? axmedAssetPairs : assetPairs).map(option => 
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>)}
                    </TextField>
                </div>
                <div className='dialogRow'>
                    <Text className='label' strong>Wallet Type: </Text>
                    <TextField
                        select
                        disabled
                        value={curAsset?.AssetWallet ? curAsset?.AssetWallet : ''}
                        onChange={(e) => handleChange('type', e)}
                        variant="standard"
                    >
                        <MenuItem value={'BTC'}>
                            {'BTC'}
                        </MenuItem>
                        <MenuItem value={'ETH'}>
                            {'ETH'}
                        </MenuItem>
                        <MenuItem value={'USDT'}>
                            {'USDT'}
                        </MenuItem>
                        <MenuItem value={'USDC'}>
                            {'USDC'}
                        </MenuItem>
                        <MenuItem value={'WBTC'}>
                            {'WBTC'}
                        </MenuItem>
                        <MenuItem value={'WETH'}>
                            {'WETH'}
                        </MenuItem>
                        <MenuItem value={'DAI'}>
                            {'DAI'}
                        </MenuItem>
                    </TextField>
                </div>
                <div className='dialogRow'>
                    <Text className='label' strong>Wallet Mode: </Text>
                    <TextField
                        select
                        value={curAsset?.Mode ? curAsset?.Mode : ''}
                        onChange={(e) => handleChange('mode', e)}
                        variant="standard"
                    >
                        <MenuItem value={'Autosell'}>
                            Auto sell
                        </MenuItem>
                        <MenuItem value={'MonitorOnly'}>
                            Monitor Only
                        </MenuItem>
                    </TextField>
                </div>
                {curAsset?.Mode === 'Autosell'? 
                <div className='dialogRow'>
                    <Text className='label' strong>Threshold: </Text>
                    <TextField
                        type="number"
                        // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        // label="Wallet Address"
                        variant="standard" 
                        sx={{width: '30%'}}
                        value={curAsset?.Threshold ? curAsset?.Threshold : ''}
                        onChange={(e) => handleChange('threshold', e)}
                    />
                </div> : null}
                <div className='dialogRow'>
                    <Text className='label' strong>Wallet Address: </Text>
                    <TextField
                        id="outlined-multiline-static"
                        // label="Wallet Address"
                        variant="standard" 
                        sx={{width: '60%'}}
                        value={curAsset?.WalletAddr ? curAsset?.WalletAddr : ''}
                        onChange={(e) => handleChange('address', e)}
                    />
                </div>
                <div className='dialogRow'>
                    <Text className='label' strong>Wallet Fee (in decimal form): </Text>
                    <TextField 
                        id="standard-basic" 
                        variant="standard" 
                        type="number" 
                        defaultValue={0.05}
                        value={curAsset?.Fee} 
                        onChange={(e) => handleChange('fee', e)}/>
                </div>
                <div className='dialogRow'>
                    <Text className='label' strong>Description: </Text>
                    <TextField
                        id="outlined-multiline-static"
                        // label="Wallet Address"
                        variant="standard" 
                        sx={{width: '60%'}}
                        value={curAsset?.Description ? curAsset?.Description : ''}
                        onChange={(e) => handleChange('description', e)}
                    />
                </div>
                <div className='dialogRow'>
                    <TextField
                        id="outlined-multiline-static"
                        label="Notes (optional)"
                        sx={{width: '100%'}}
                        multiline
                        rows={4}
                        value={curAsset?.Notes && curAsset?.Notes !== "\"\"" ? curAsset?.Notes : ""}
                        onChange={(e) => handleChange('notes', e)}
                    />
                </div>
            </DialogContent>
            <DialogActions sx={{marginRight: '15px'}}>
                <Button onClick={() => closeDialog()} color="error">Cancel</Button>
                <Button onClick={() => onHandleSaveNewWallet()} variant="contained" color="success" 
                disabled={!curAsset || !curAsset.AssetPair || !curAsset.AssetWallet || !curAsset.Mode || !curAsset.WalletAddr || !curAsset.Description || !curAsset.LiqProvider}>Save</Button>
            </DialogActions>
        </Dialog>
    </div>
    );
}

